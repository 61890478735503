@use "global" as *;
.l-automaticMoldCleaningMachine__content{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-automaticMoldCleaningMachine-top{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(30);
    }
}
.l-automaticMoldCleaningMachine__images{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(50);
    }
}