@use "global" as *;
.c-btn{
    font-family: $base-font-family;
    font-size: rem(14);
    line-height: calc(24/16);
    letter-spacing: 0.1em;
    font-weight: 400;
    padding-right: rem(50);
    position: relative;
    transition: all 0.5s;
    @include mq(md){
    font-size: rem(16);
    }
}
.c-btn:hover{
    opacity: 0.6;
}
.c-btn::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $black;
    transition: transform 0.4s ease-in-out, transform 0.4s ease-in-out;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform-origin: left;
    -webkit-transform-origin: left;
}

/*矢印の設定*/
.c-btn::after {
    content:url(../images/common/arrow.png);
    position: absolute;
    top: calc(50% - 0.5rem);
    right: 0;
    display: block;
    width: 16px;
    width: 1rem;
    height: 16px;
    height: 1rem;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    animation: arrow_to_left 0.5s ease-in-out forwards;
    background-image: url(../images/common/arrow.png);
    display: inline-block;
}

/*線と矢印を繰り返しアニメーション*/
.c-btn:hover::before {
    animation: arrowlong01 2s ease infinite;
}
.c-btn:hover::after {
    animation: arrow_to_right 0.5s ease-in-out forwards;
}

@keyframes arrowlong01{
    0%{
        width:0;opacity:0
    }
    20%{
        width:0;opacity:1
    }
    80%{
        width:100%;opacity:1
    }
    100%{
        width:100%;opacity:0
    }
}

@keyframes arrow_to_right {
    0% {
    }
    30% {
      opacity: 1;
    }
    50% {
      transform: translateX(2rem);
      opacity: 0;
    }
    70% {
      transform: translateX(-2rem);
      opacity: 0;
    }
    100% {
    }
  }
  .c-btn__access{
    text-align: center;
}

