@use "global" as *;
.l-greet{
    margin-top: rem(90);
    @include mq(md){
    margin-top: rem(120);
    }
}
.l-greet__body{
    margin-top: rem(30);
    @include mq(md){
        margin-top: 0;
}
}
.l-greet__body-item{
    margin-top: rem(20);
}
.l-greet__name{
    margin-top: rem(30);
}
