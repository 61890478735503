@use "global" as *;
.p-pc-nav{
    display: none;
    @include mq(md) {
        height: inherit;
        display: block;
    }
    }
    .p-pc-nav__items{
        height: inherit;
        display: flex;
        align-items: center;
    }
    .p-pc-nav__item{
        height: inherit;
        position: relative;
        font-family:$base-font-family;
    }
    .p-pc-nav__contact{
        background-color:#87C9A3;
        color: #fff;
        width: rem(150);
        height: rem(45);
    }

    .p-pc-nav__link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: inherit;
        transition: opacity 0.5s;
        @include mq(md){
            position: relative;
            padding: 0 rem(22);
            transition: 1s;
            transform: rotateX( 0deg );
        }
    }
    .p-pc-nav__contact a.p-pc-nav__link{
        @include mq(md){
            padding: rem(12) rem(20);
            height: inherit;
            transition:.5s;
            transform: unset;
        }
    }
    .p-pc-nav__contact a.p-pc-nav__link:hover{
        background-color: #13528B;
    }
    // .p-pc-nav__link::after {
    //     content:"";
    //     position: absolute;
    //     left: 0;
    //     width: 100%;
    //     height: 2px;
    //     background: #000000;
    //     bottom: rem(10);
    //     transform: scale(0, 1);
    //     transform-origin: center top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
    //     transition: transform 0.5s;   /*変形の時間*/
    //     }
        
    // .p-pc-nav__link:hover::after {
    //     transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
    //     }
    .p-pc-nav__link:hover{
        // opacity: 0.6;
        transition: 1s ease-in-out;
        transform: rotateX( 360deg );
    }
    .p-pc-nav__item--drop{
        display: flex;
        flex-direction: column;
        position: relative;
        transition: opacity 2s ease, visibility 2s ease;
    }
    .p-pc-nav__items--bottom{
        display: none;
        transition: all 2s ease;
        height: inherit;
        width: 100%;
    }
    .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom{
        display: block;
        position: absolute;
        top: rem(75);
        width: 153px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        @media screen and (min-width:1200px) and (max-width:1599px){
            bottom: -120px;
        }
    }
    // .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom-company{
    //     bottom: -209px;
    //     @media screen and (min-width:1200px) and (max-width:1599px){
    //         bottom: -209px;
    //     }
    // }
    // .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom-advantage,
    // .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom-vehicle{
    //     bottom: -160px;
    //     @media screen and (min-width:1200px) and (max-width:1599px){
    //         bottom: -160px;
    //     }
    // }
    // .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom-safety{
    //     bottom: -300px;
    //     @media screen and (min-width:1200px) and (max-width:1599px){
    //         bottom: -300px;
    //     }
    // }
    // .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom-recruit{
    //     bottom: -110px;
    //     @media screen and (min-width:1200px) and (max-width:1599px){
    //         bottom: -110px;
    //     }
    // }
    
    .p-pc-nav__item--bottom{
        font-size: rem(12);
        background-color: $white;
        opacity: 0.75;
        color: $black;
        height: rem(45);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    .p-pc-nav__item--bottom a{
        height: inherit;
        padding: rem(14) rem(20);
        transition: opacity 0.5s;
        position: relative;
    }
    .p-pc-nav-bottom__link{
        transition: opacity 0.5s;
        position: relative;
    }
    .p-pc-nav-bottom__link::after {
        content:"";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: #000000;
        bottom: rem(5);
        transform: scale(0, 1);
        transform-origin: center top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
        transition: transform 0.5s;   /*変形の時間*/
        }
        .p-pc-nav-bottom__link:hover::after {
            transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
        }
    
    