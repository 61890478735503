@use "global" as *;
.p-article__inner{
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(25);
    padding-right: rem(25);
    @include mq(md){
    padding-left: rem(20);
    padding-right: rem(20);
}
}
.p-article__content{
    font-family: $base-font-family;
    color: $black;
}
.p-article__content h1{
font-size: rem(24);
font-weight: 600;
line-height: calc(32/24);
letter-spacing: 0.05em;
}
.p-article__date{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(46);
}
}
.p-article__date p,
.p-article__text-top p,
.p-article__text-second p,
.p-article__text-bottom p,
.p-article__content ul li{
    font-size: rem(16);
    line-height: calc(32/16);
    list-style-image: 0.05em;
}
.p-article__text-top,
.p-article__text-second,
.p-article__text-bottom
{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(46);
    }
}
.p-article__content h2{
    font-size: rem(20);
    font-weight: 600;
    line-height: calc(32/24);
    letter-spacing: 0.05em;
    margin-top: rem(20);
    }
.p-article__content h3{
    font-size: rem(18);
    font-weight: 600;
    line-height: calc(32/24);
    letter-spacing: 0.05em;
    margin-top: rem(20);
    }
.p-article__content ul{
    list-style: disc;
    margin-top: rem(20);
}
.p-article__content p{
    margin-top: rem(20);
}
.p-article__content .nav-links{
    margin-top: rem(60);
    text-align: center;
}
.p-article__btn{
    text-align: center;
}
.p-article__content figure {
    margin-top: rem(30);
}