@use "global" as *;
.p-access__inner{
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.p-access__content{
    display: block;
    @include mq(md){
    display: flex;
    flex-direction: row-reverse;
}
}
.p-access__body{
    background-color: $green;
    width: 100%;
    padding: rem(30);
    @include mq(md){
    width: 51%;
    padding-top: rem(70);
    padding-left: rem(70);
}
}
.p-access__title{
    text-transform: uppercase;
    font-family: $second-font-family;
    font-size: rem(30);
    font-weight: bold;
    line-height: calc(40/30);
    letter-spacing: 0.1em;
    color: $white;
    text-align: center;
    @include mq(md){
    font-size: rem(60);
    line-height: calc(80/60);
    text-align: left;
}
}
.p-access__title span{
    display: block;
    font-family: $second-font-family;
    font-size: rem(15);
    font-weight: bold;
    line-height: calc(20/15);
    letter-spacing: 0.1em;
    color: $white;
    text-align: center;
    @include mq(lg){
    font-size: rem(17);
    line-height: calc(24/17);
    text-align:left;
}
    @media screen and (min-width:769px) and (max-width:1023px){
        font-size: rem(30);
        max-width: 480px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }
}
.p-access-address{
    font-family: $second-font-family;
    color: $white;
}
.p-access-address p,
.p-access-tel p,
.p-access-time{
    font-size: rem(16);
    line-height: calc(24/16);
    letter-spacing: 0.1em;
}
.p-access-tel p span{
    font-size: rem(30);
    line-height: calc(45/30);
    letter-spacing: 0.1em;
}
.p-access__map{
    width: 100%;
    @include mq(md){
        width: 49%;
}
}
.p-access__map iframe{
    width: 100%;
    aspect-ratio: 711/635;
}