@use "global" as *;
.p-interview__inner{
    max-width: 1340px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq(md){
        padding-left: rem(25);
        padding-right: rem(25);
}
}
.p-interview__item{
    display: block;
    background: linear-gradient(180deg, transparent 0%, transparent 20%, #fff 20%, #fff 100%);
    @include mq(md){
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, transparent 0%, transparent 30%, #fff 30%, #fff 100%);
}
}
.p-interview__img{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @include mq(md){
    width: 45.9%;
    margin-right: rem(100);
}
}
.p-interview__body{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: rem(-50);
    padding-bottom: rem(50);
    @include mq(md){
    width: 31.39%;
    background-color: $white;
    padding-top: rem(113);
    padding-bottom: rem(96);
}
}
.p-interview__body h3{
font-family: $second-font-family;
font-size: rem(18);
line-height: calc(40/24);
letter-spacing: 0.05em;
@include mq(md){
font-size: rem(24);
}
}
.p-interview__body span:nth-of-type(1){
    display: block;
}
.p-interview__body span:nth-of-type(2){
    font-size: rem(28);
    @include mq(md){
    font-size: rem(32);
}
}
.p-interview__body p{
    margin-top: rem(25);
    font-family:$base-font-family;
    font-size: rem(14);
    line-height: calc(32/16);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(16);    
}
}
