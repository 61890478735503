@use "global" as *;
.p-entry-btn{
    max-width: 300px;
    width: 100%;
    height: rem(70);
    margin-left: auto;
    margin-right: auto;
    background-color: $ltGreen;
    @include mq(md){
        max-width: 900px;
        height: rem(120);
    }
}
.p-entry-btn a{
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $second-font-family;
    font-size: rem(20);
    font-weight: normal;
    line-height: calc(40/32);
    letter-spacing: 0.1em;
    color: $white;
    position: relative;
    @include mq(md){
    font-size: rem(32);
}
}
.p-entry-btn a::before {
    content: "";
    position: absolute;
    top: 50%;
    right:5% ;
    width: 15px;
    height: 2px;
    margin-top: 0px;
    background-color: $white;
    @include mq(md){
        right: 20%;
}
}
.p-entry-btn a::after{
    position: absolute;
    top: 50%;
    right: 4.9%;
    width: 12px;
    height: 12px;
    margin-top: -5px;
    transform: rotate(45deg);
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    content: "";
    display: inline-block;
    // vertical-align: middle;
    @include mq(md){
        right: 20%;
}
}
