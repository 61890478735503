@charset "UTF-8";
body {
  font-family: "'Noto Sans JP', sans-serif";
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 769px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .u-mobile {
    display: none;
  }
}

/* リキッドレイアウト対応 */
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 769px) {
  html {
    font-size: 1.5vw;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
@media screen and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
@media screen and (min-width: 769px) {
  a:hover {
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 769px) {
  .inner {
    max-width: 1250px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.test {
  font-size: 1.25rem;
  background-color: #555;
  color: #fff;
}

.p-about__inner {
  padding: 5.625rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-about__inner {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5.9375rem;
    padding-top: 6.25rem;
    padding-bottom: 7.5rem;
  }
}

.p-about__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-about__content {
    display: flex;
    align-items: center;
  }
}

.p-about__title {
  text-transform: uppercase;
  writing-mode: horizontal-tb;
  font-family: "Barlow", sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-about__title {
    writing-mode: vertical-rl;
    font-size: 6.5rem;
    line-height: 1.1538461538;
    text-align: unset;
  }
}

.p-about__body {
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-about__body {
    max-width: 28.75rem;
  }
}

.p-about__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-about__body-title {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

.p-about__body-title::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}
@media screen and (min-width: 769px) {
  .p-about__body-title::after {
    bottom: 0;
  }
}

.p-about__body-text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #555;
}

.p-about__img {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-about__img {
    width: 44%;
    margin-left: auto;
  }
}

.p-about__img img {
  aspect-ratio: 600/700;
}
@media screen and (min-width: 769px) {
  .p-about__img img {
    aspect-ratio: 640/996;
  }
}

.p-access__inner {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.p-access__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-access__content {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-access__body {
  background-color: #004020;
  width: 100%;
  padding: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-access__body {
    width: 51%;
    padding-top: 4.375rem;
    padding-left: 4.375rem;
  }
}

.p-access__title {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-access__title {
    font-size: 3.75rem;
    line-height: 1.3333333333;
    text-align: left;
  }
}

.p-access__title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .p-access__title span {
    font-size: 1.0625rem;
    line-height: 1.4117647059;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .p-access__title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.p-access-address {
  font-family: "Barlow", sans-serif;
  color: #fff;
}

.p-access-address p,
.p-access-tel p,
.p-access-time {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.p-access-tel p span {
  font-size: 1.875rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.p-access__map {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-access__map {
    width: 49%;
  }
}

.p-access__map iframe {
  width: 100%;
  aspect-ratio: 711/635;
}

.p-article__inner {
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
@media screen and (min-width: 769px) {
  .p-article__inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.p-article__content {
  font-family: "'Noto Sans JP', sans-serif";
  color: #555;
}

.p-article__content h1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
}

.p-article__date {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-article__date {
    margin-top: 2.875rem;
  }
}

.p-article__date p,
.p-article__text-top p,
.p-article__text-second p,
.p-article__text-bottom p,
.p-article__content ul li {
  font-size: 1rem;
  line-height: 2;
  list-style-image: 0.05em;
}

.p-article__text-top,
.p-article__text-second,
.p-article__text-bottom {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-article__text-top,
  .p-article__text-second,
  .p-article__text-bottom {
    margin-top: 2.875rem;
  }
}

.p-article__content h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
  margin-top: 1.25rem;
}

.p-article__content h3 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
  margin-top: 1.25rem;
}

.p-article__content ul {
  list-style: disc;
  margin-top: 1.25rem;
}

.p-article__content p {
  margin-top: 1.25rem;
}

.p-article__content .nav-links {
  margin-top: 3.75rem;
  text-align: center;
}

.p-article__btn {
  text-align: center;
}

.p-article__content figure {
  margin-top: 1.875rem;
}

.p-automaticMoldCleaningMachine__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-automaticMoldCleaningMachine__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-automaticMoldCleaningMachine-top {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-automaticMoldCleaningMachine-top {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.p-automaticMoldCleaningMachine-top p:nth-of-type(2) {
  margin-top: 0.625rem;
}

.p-automaticMoldCleaningMachine__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5625rem;
}
@media screen and (min-width: 769px) {
  .p-automaticMoldCleaningMachine__images {
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-automaticMoldCleaningMachine__img p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}

.p-balloon__items {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-balloon__items {
    max-width: 970px;
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-balloon__item {
  display: flex;
  align-items: center;
  background-color: #004020;
  padding: 1.875rem 2.5rem;
  position: relative;
}

.p-balloon__item:nth-of-type(1):before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 30px solid transparent;
  border-top: 40px solid #004020;
}

.p-balloon__item:nth-of-type(n + 2) {
  margin-top: 3.75rem;
}

.p-stepCircle {
  width: 6.25rem;
  margin-right: 3.125rem;
}

.p-balloon__item p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .p-balloon__item p {
    font-size: 1rem;
  }
}

.p-breadcrumb__items {
  display: flex;
}

.p-breadcrumb-item a {
  font-family: "Barlow", sans-serif;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  font-weight: normal;
  padding: 0 0.625rem;
}

.p-breadcrumb-item span {
  padding: 0 0.625rem;
}

.p-breadcrumb-recruit {
  background-color: #004020;
  color: #fff;
  display: flex;
  justify-content: end;
}

.p-cadDesign__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 5rem;
  }
}

.p-cadDesign__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-cadDesign__body-wrap {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__body-wrap {
    display: flex;
    justify-content: space-between;
  }
}

.p-cadDesign__body {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__body {
    width: 40.7%;
  }
}

.p-cadDesign__body p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__body p {
    font-size: 1.25rem;
  }
}

.p-cadDesign__img {
  width: 100%;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-cadDesign__img {
    width: 43.8%;
    margin-top: 0;
  }
}

.p-entry-btn {
  max-width: 300px;
  width: 100%;
  height: 4.375rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #87C9A3;
}
@media screen and (min-width: 769px) {
  .p-entry-btn {
    max-width: 900px;
    height: 7.5rem;
  }
}

.p-entry-btn a {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.1em;
  color: #fff;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-entry-btn a {
    font-size: 2rem;
  }
}

.p-entry-btn a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 5%;
  width: 15px;
  height: 2px;
  margin-top: 0px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .p-entry-btn a::before {
    right: 20%;
  }
}

.p-entry-btn a::after {
  position: absolute;
  top: 50%;
  right: 4.9%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  transform: rotate(45deg);
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  content: "";
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .p-entry-btn a::after {
    right: 20%;
  }
}

.p-facility__inner {
  max-width: 100%;
  padding: 0 1.25rem;
  padding-bottom: 5.625rem;
}
@media screen and (min-width: 769px) {
  .p-facility__inner {
    max-width: 1440px;
    width: 100%;
    padding: 0 3rem;
    padding-bottom: 7.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-facility__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-facility__content {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-facility__title {
  text-transform: uppercase;
  writing-mode: horizontal-tb;
  font-family: "Barlow", sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-facility__title {
    writing-mode: vertical-rl;
    font-size: 6.5rem;
    line-height: 1.1538461538;
    text-align: unset;
  }
}

.p-facility__title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .p-facility__title span {
    font-size: 2.5rem;
    line-height: 1.5;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .p-facility__title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.p-facility__body {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .p-facility__body {
    max-width: 31.9%;
    width: 100%;
    margin-left: auto;
    margin-right: 3.125rem;
  }
}

.p-facility__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-facility__body-title {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

.p-facility__body-title::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}
@media screen and (min-width: 769px) {
  .p-facility__body-title::after {
    bottom: 0;
  }
}

.p-facility__body-text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #555;
}

.p-facility__img {
  margin-right: 0;
  width: 100%;
  display: block;
}
@media screen and (min-width: 769px) {
  .p-facility__img {
    margin-right: 3.125rem;
    width: 40%;
  }
}

.p-facility__img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 584/939;
}

.p-factory__inner {
  padding: 0 1.25rem;
  padding-bottom: 5.625rem;
}
@media screen and (min-width: 769px) {
  .p-factory__inner {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5625rem;
    padding-bottom: 7.5rem;
  }
}

.p-factory__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-factory__content {
    display: flex;
    align-items: center;
  }
}

.p-factory__title {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-factory__title {
    font-size: 6.5rem;
    line-height: 1.1538461538;
    text-align: unset;
  }
}

.p-factory__title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .p-factory__title span {
    font-size: 2.5rem;
    line-height: 1.5;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .p-factory__title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.p-factory__body {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .p-factory__body {
    max-width: 38.8%;
    width: 100%;
    margin-left: auto;
    margin-right: 4.6875rem;
  }
}

.p-factory__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-factory__body-title {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

.p-factory__body-title::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}
@media screen and (min-width: 769px) {
  .p-factory__body-title::after {
    bottom: 0;
  }
}

.p-factory__body-text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #555;
}

.p-factory__img {
  margin-right: 0;
  width: 100%;
  display: block;
}
@media screen and (min-width: 769px) {
  .p-factory__img {
    width: 50%;
    transform: translateX(20px);
  }
}

.p-factory__img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 720/792;
}

.p-feature__content {
  position: relative;
  height: 23.125rem;
  display: block;
}

.p-feature__content img {
  height: 68%;
}
@media screen and (min-width: 769px) {
  .p-feature__content img {
    height: 100%;
  }
}

.p-feature__content h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.875rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .p-feature__content h2 {
    font-size: 3.125rem;
  }
}

h2.p-feature__title span {
  text-align: left !important;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (min-width: 769px) {
  h2.p-feature__title span {
    font-size: 1.25rem;
  }
}

.p-firstPlant__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-firstPlant__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 11.25rem;
  }
}

.p-firstPlant-sub__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-sub__title {
    font-size: 2.5rem;
  }
}

.p-firstPlant-top p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.1428571429;
  letter-spacing: 0.05em;
  text-align: left;
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-top p {
    font-size: 1rem;
    text-align: center;
    max-width: 59.375rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
  }
}

.p-firstPlant-top__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-top__images {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5625rem;
  }
}

.p-firstPlant-moldingMachine__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-moldingMachine__images {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5625rem;
  }
}

.p-firstPlant-processingMachine__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-processingMachine__images {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5625rem;
  }
}

.p-firstPlant-cad__body-wrap {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-cad__body-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-firstPlant-cad__body {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-cad__body {
    width: 40%;
  }
}

.p-firstPlant-cad__body h4 {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-cad__body h4 {
    font-size: 1.25rem;
  }
}

.p-firstPlant-cad__body img {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-cad__body img {
    margin-top: 1.875rem;
  }
}

.p-firstPlant-cad__img {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-cad__img {
    width: 44%;
  }
}

.p-firstPlant-measuringInstrument-list {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-measuringInstrument-list {
    width: 68.5%;
  }
}

.p-firstPlant-measuringInstrument__items {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-measuringInstrument__items {
    display: flex;
  }
}

.p-firstPlant-measuringInstrument__item {
  display: block;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-measuringInstrument__item {
    width: 45%;
  }
}

.p-firstPlant-measuringInstrument__item:nth-of-type(1) {
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-measuringInstrument__item:nth-of-type(1) {
    margin-right: 6.25rem;
  }
}

.p-firstPlant-measuringInstrument__item:nth-of-type(2) {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-firstPlant-measuringInstrument__item:nth-of-type(2) {
    margin-top: 0;
  }
}

.p-firstPlant-measuringInstrument__item img {
  width: 100%;
  height: 100%;
}

.p-firstPlant-measuringInstrument__text {
  width: 100%;
  margin-top: 1.25rem;
}

.p-footer {
  background-color: #F7F7F7;
}

.p-footer__inner {
  padding: 3.75rem 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-footer__inner {
    padding: 10rem 4.375rem 6.25rem 4.5rem;
  }
}

.p-footer__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-footer__content-left {
  display: block;
  font-family: "'Noto Sans JP', sans-serif";
  color: #555;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-footer__content-left {
    display: flex;
  }
}

@media screen and (min-width: 769px) {
  .p-footer__img {
    margin-right: 1.875rem;
  }
}

.p-footer__img img {
  width: 9.375rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 769px) {
  .p-footer__img img {
    width: 12.5rem;
    height: auto;
    object-fit: cover;
  }
}

.p-footer__info-title {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-footer__info-title {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.p-footer__info-post,
.p-footer__info-address,
.p-footer__info-tel {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-footer__info-post,
  .p-footer__info-address,
  .p-footer__info-tel {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.p-footer__nav {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-footer__nav {
    display: flex;
    color: #555;
  }
}

.p-footer__nav-items1 {
  margin-right: 0;
  display: flex;
  justify-content: space-around;
}
@media screen and (min-width: 769px) {
  .p-footer__nav-items1 {
    display: block;
    margin-right: 3.75rem;
  }
}

.p-footer__nav-item {
  padding: 0.625rem 0;
}

.p-footer__nav-items--bottom {
  display: none;
}
@media screen and (min-width: 769px) {
  .p-footer__nav-items--bottom {
    display: block;
    padding-left: 0.9375rem;
    margin-left: 1.25rem;
    padding-top: 0.9375rem;
    position: relative;
  }
}

.p-footer__nav-items--bottom::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1px;
  height: 80%;
  background-color: #555;
}

.p-footer__nav-item--bottom:nth-child(n+2) {
  padding-top: 0.3125rem;
}

.p-footer__nav-items2 {
  display: flex;
  justify-content: space-around;
}
@media screen and (min-width: 769px) {
  .p-footer__nav-items2 {
    display: block;
  }
}

.p-footer__copy {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 1.2857142857;
  letter-spacing: 0.1em;
  text-align: center;
}

.p-form__inner {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 6.25rem;
}
@media screen and (min-width: 769px) {
  .p-form__inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.p-form-top__text {
  text-align: left;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-form-top__text {
    text-align: center;
    font-size: 1rem;
  }
}

.p-form__item {
  display: block;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-form__item {
    display: flex;
    justify-content: center;
    font-size: 1rem;
  }
}

.p-form__item dt {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-form__item dt {
    width: 20%;
  }
}

.p-form__item dd {
  width: 100%;
  border: 1px solid #C2BFBF;
  margin-top: 0.3125rem;
  padding: 0.5rem;
}
@media screen and (min-width: 769px) {
  .p-form__item dd {
    width: 50%;
    margin-top: 0;
  }
}

.p-form__item textarea {
  width: 100%;
  padding: 0.5rem;
}

.p-form__privacy {
  max-width: 100%;
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 769px) {
  .p-form__privacy {
    max-width: 300px;
    white-space: nowrap;
  }
}

.p-form__privacy p,
.p-form__privacy label {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-form__privacy p,
  .p-form__privacy label {
    font-size: 1rem;
  }
}

.p-form__privacy a {
  text-decoration: underline;
}

.p-form__privacy label {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}

.wpcf7-list-item {
  margin: 0;
}

.wpcf7-list-item input {
  display: block;
  appearance: auto;
  width: 1.25rem;
  height: 1.25rem;
  transform: translateY(8px);
}

.btn_contact7 input {
  width: 7.5rem;
  padding: 0.875rem 2.6875rem;
  background-color: #87C9A3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: opacity 0.5s;
  margin-top: 3.125rem;
  cursor: pointer;
}
@media screen and (min-width: 769px) {
  .btn_contact7 input {
    margin-left: auto;
    margin-right: auto;
  }
}

.p-form-thanks__btn {
  width: 12.5rem;
  margin-left: auto;
  margin-right: auto;
}

.p-form__submit:hover,
.p-form-thanks__btn:hover,
.p-form__submit-back:hover {
  opacity: 0.6;
}

.box__checked {
  text-align: center;
  margin-bottom: 48px;
}
.box__checked .form-item {
  margin-bottom: 28px;
}

.p-form__checkbox {
  opacity: 0;
}

.privacy-text {
  position: relative;
  padding-left: 1.875rem;
}
.privacy-text::before {
  content: "";
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  left: 0;
  background-color: #fff;
  border: 1px solid #707070;
}

.p-form__checkbox:checked + .privacy-text::after {
  content: "";
  width: 4px;
  height: 8px;
  border-right: 2px solid #1B224C;
  border-bottom: 2px solid #1B224C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 20%;
  left: 2.5%;
}

.p-form-confirm__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-form__submit-confirm {
  margin-right: 1.875rem;
  margin-left: 0;
}

.p-form__submit-back {
  color: #87C9A3;
  background-color: #fff;
  border: 1px solid #87C9A3;
}

/* Contact Form7 */
.contact7 {
  max-width: 100%;
  box-sizing: border-box;
}

.contact7 dd {
  margin: 0 0 30px 0;
  background-color: #FFF;
}

.contact7 dt {
  font-weight: normal;
}

.btn_contact7 {
  text-align: center;
}

.btn_contact7 input {
  width: 70%;
  background-color: #005513;
  color: #FFF;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0.2em;
  border: 1px solid #005513;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.btn_contact7 input:hover {
  background-color: #FFF;
  color: #005513;
}

@media screen and (min-width: 768px) {
  .contact7 dt {
    float: left;
    clear: left;
    width: 35%;
    padding: 5px 10px 5px 0;
  }
  .contact7 dd {
    margin-left: 35%;
  }
  .btn_contact7 input {
    width: 70%;
    background-color: #005513;
    color: #FFF;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.2em;
    border: 1px solid #005513;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
  }
  .btn_contact7 input:hover {
    background-color: #FFF;
    color: #005513;
  }
}
.p-fv__body img {
  height: 33.75rem;
}

@media screen and (max-width: 767px) {
  .p-fv__body--about img {
    height: 40vh;
  }
}

.p-greet__inner {
  max-width: 100%;
  width: 100%;
  padding: 0 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-greet__inner {
    max-width: 1100px;
    width: 100%;
    padding: 0 1.5625rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-greet__content-inner {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-greet__content-inner {
    display: flex;
    align-items: center;
  }
}

.p-greet__img {
  margin-right: 0;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-greet__img {
    width: 60%;
    margin-right: 3.75rem;
  }
}

.p-greet__body {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-greet__body {
    width: 50%;
  }
}

.p-greet__body-item {
  max-width: 410px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.p-greet__body-text p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-greet__body-text p {
    font-size: 1rem;
  }
}

.p-greet__body-text p:nth-of-type(1),
.p-greet__body-text p:nth-of-type(3),
.p-greet__body-text p:nth-of-type(4) {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.p-greet__body-text ol {
  list-style: decimal;
}

.p-greet__name span {
  font-family: "'Noto Sans JP', sans-serif";
  letter-spacing: 0.1em;
}

.p-greet__name span {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .p-greet__name span {
    font-size: 1.25rem;
  }
}

.p-greet__name p {
  margin-top: 0.3125rem;
  font-size: 1.875rem;
  line-height: 1.25;
}
@media screen and (min-width: 769px) {
  .p-greet__name p {
    margin-top: 0.625rem;
    font-size: 2.5rem;
  }
}

h2.p-greet__title,
h2.p-greet__title span {
  text-align: left;
}

h2.p-head {
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 2.5rem;
  line-height: 1.25;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  h2.p-head {
    font-size: 3.75rem;
    line-height: 1.1666666667;
  }
}

h2.p-head span {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
  text-align: left;
}
@media screen and (min-width: 769px) {
  h2.p-head span {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-header {
  height: 75px;
  background-color: #fff;
}

.p-header__inner {
  padding: 0 2.25rem 0 1.25rem;
  max-width: 100%;
  width: 100%;
  height: inherit;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .p-header__inner {
    padding: 0 1.25rem;
  }
}

.p-header__content {
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

/*==ふわっと出現させるためのCSS*/
/*　上に上がる動き　*/
#header.UpMove {
  position: fixed;
  width: 100%;
  animation: UpAnime 0.5s forwards;
}

@keyframes UpAnime {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}
/*　下に下がる動き　*/
#header.DownMove {
  position: fixed;
  width: 100%;
  animation: DownAnime 0.5s forwards;
}

@keyframes DownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.p-inspection__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 769px) {
  .p-inspection__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 5rem;
  }
}

.p-inspection__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-inspection__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-inspection__text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #000;
}

.p-interview__inner {
  max-width: 1340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-interview__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-interview__item {
  display: block;
  background: linear-gradient(180deg, transparent 0%, transparent 20%, #fff 20%, #fff 100%);
}
@media screen and (min-width: 769px) {
  .p-interview__item {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, transparent 0%, transparent 30%, #fff 30%, #fff 100%);
  }
}

.p-interview__img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 769px) {
  .p-interview__img {
    width: 45.9%;
    margin-right: 6.25rem;
  }
}

.p-interview__body {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: -3.125rem;
  padding-bottom: 3.125rem;
}
@media screen and (min-width: 769px) {
  .p-interview__body {
    width: 31.39%;
    background-color: #fff;
    padding-top: 7.0625rem;
    padding-bottom: 6rem;
  }
}

.p-interview__body h3 {
  font-family: "Barlow", sans-serif;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-interview__body h3 {
    font-size: 1.5rem;
  }
}

.p-interview__body span:nth-of-type(1) {
  display: block;
}

.p-interview__body span:nth-of-type(2) {
  font-size: 1.75rem;
}
@media screen and (min-width: 769px) {
  .p-interview__body span:nth-of-type(2) {
    font-size: 2rem;
  }
}

.p-interview__body p {
  margin-top: 1.5625rem;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-interview__body p {
    font-size: 1rem;
  }
}

.p-jobInformation__inner {
  max-width: 1250px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 3.75rem;
}
@media screen and (min-width: 769px) {
  .p-jobInformation__inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 7.5rem;
  }
}

.p-jobInformation__inner h2 {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  line-height: 1.1428571429;
  letter-spacing: 0.2em;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-jobInformation__inner h2 {
    font-size: 4.375rem;
  }
}

.p-jobInformation1,
.p-jobInformation2 {
  background-color: #fff;
}

.p-jobInformation1__inner,
.p-jobInformation2__inner {
  max-width: 1040px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-jobInformation1__inner,
  .p-jobInformation2__inner {
    padding: 3.75rem 6.25rem;
  }
}

.p-jobInformation1__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  line-height: 1.25;
  letter-spacing: 0.05em;
  color: #555;
}
@media screen and (min-width: 769px) {
  .p-jobInformation1__title {
    font-size: 2rem;
  }
}

.p-jobInformation1__items,
.p-jobInformation2__items {
  margin-top: 2.5rem;
}

.p-jobInformation1__item,
.p-jobInformation2__item {
  display: flex;
  padding: 1.875rem 0;
  border-top: 1px solid #555;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #555;
}
@media screen and (min-width: 769px) {
  .p-jobInformation1__item,
  .p-jobInformation2__item {
    font-size: 0.9375rem;
  }
}

.p-jobInformation1__item:last-of-type,
.p-jobInformation2__item:last-of-type {
  border-bottom: 1px solid #555;
}

.p-jobInformation1__item dt {
  width: 25%;
  padding-left: 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-jobInformation1__item dt {
    width: 20%;
    padding-left: 3.125rem;
  }
}

.p-mainFacility__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-mainFacility__title {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

.p-mainFactory__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-mainFactory__title {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

.p-message-top__inner {
  max-width: 72.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-message-top__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-message-top__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-message-top__content {
    display: flex;
  }
}

.p-message-top__body {
  width: 100%;
  margin-right: 0;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .p-message-top__body {
    width: 40%;
    margin-right: 8.75rem;
  }
}

.p-message-top__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  line-height: 1.4;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .p-message-top__body-title {
    font-size: 2.625rem;
  }
}

.p-message-top__body-text p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.01em;
}
@media screen and (min-width: 769px) {
  .p-message-top__body-text p {
    font-size: 1rem;
  }
}

.p-message-top__img {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-message-top__img {
    width: 50.8%;
  }
}

.p-message-top__img p {
  text-align: right;
  font-size: 1.25rem;
  line-height: 1.6666666667;
  letter-spacing: 0.05em;
  color: #fff;
}

.p-mesuringMachine__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 10rem;
  }
}

.p-mesuringMachine__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-mesuringMachine-top {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine-top {
    width: 60%;
    margin-right: auto;
  }
}

.p-mesuringMachine__body-wrapper {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__body-wrapper {
    display: flex;
  }
}

.p-mesuringMachine__body1 {
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__body1 {
    margin-right: 6.25rem;
  }
}

.p-mesuringMachine__body1 img,
.p-mesuringMachine__body2 img {
  width: 100%;
  height: 100%;
}

.p-mesuringMachine__body1 p {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__body1 p {
    margin-top: 0;
  }
}

.p-mesuringMachine__body2 img {
  margin-right: 0;
}

.p-mesuringMachine__body2 p {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__body2 p {
    display: block;
    margin-right: 6.25rem;
    margin-top: 0;
  }
}

.p-mesuringMachine__body1 p,
.p-mesuringMachine__body2 p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.875;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-mesuringMachine__body1 p,
  .p-mesuringMachine__body2 p {
    font-size: 1rem;
  }
}

.p-moldingFactory__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-moldingFactory__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 5rem;
  }
}

.p-moldingFactory__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-moldingFactory__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-moldingFactory__block h3 {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-moldingFactory__block h3 {
    font-size: 1.25rem;
    line-height: 2;
  }
}

.p-moldingFactory__block p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-moldingFactory__block p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2;
  }
}

.p-moldingFactory__block p:nth-of-type(2) {
  margin-top: 0.75rem;
}

.p-moldingMachine__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-moldingMachine__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 5rem;
  }
}

.p-moldingMachine__img-top p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}

.p-moldingMachine__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5625rem;
}
@media screen and (min-width: 769px) {
  .p-moldingMachine__images {
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-moldingMachine__img p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-moldingMachine__img p {
    font-size: 0.625rem;
  }
}

.p-moldUltrasonicCleaningMachine__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #000;
}
@media screen and (min-width: 769px) {
  .p-moldUltrasonicCleaningMachine__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }
}

.p-moldUltrasonicCleaningMachine__body {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-moldUltrasonicCleaningMachine__body {
    display: flex;
    justify-content: space-between;
  }
}

.p-moldUltrasonicCleaningMachine-top {
  width: 100%;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-moldUltrasonicCleaningMachine-top {
    width: 46.5%;
  }
}

.p-moldUltrasonicCleaningMachine-top p:nth-of-type(3) {
  margin-top: 1.875rem;
}

.p-moldUltrasonicCleaningMachine__img {
  width: 100%;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-moldUltrasonicCleaningMachine__img {
    width: 33%;
    margin-top: 0;
  }
}

.p-mv__content {
  padding-bottom: 5.625rem;
  background-color: #004020;
}

.p-mv__body {
  position: relative;
}

.p-mv__body img {
  width: 90%;
  margin-left: auto;
  height: 100vh;
  aspect-ratio: 768/967;
}

.p-mv__body-recruit img {
  height: auto;
}
@media screen and (min-width: 769px) {
  .p-mv__body-recruit img {
    height: 100vh;
  }
}

.p-mv__title {
  position: absolute;
  bottom: 15%;
  left: 15%;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .p-mv__title {
    font-size: 3.75rem;
    line-height: 1.3333333333;
    font-weight: bold;
  }
}

h2.p-mv__title-recruit {
  text-align: left;
  font-size: 5rem;
  color: #fff;
  letter-spacing: 0;
  position: relative;
}
@media screen and (min-width: 769px) {
  h2.p-mv__title-recruit {
    font-size: 12.5rem;
  }
}

h2.p-mv__title-recruit span {
  font-family: "Barlow", sans-serif;
  color: #fff;
  text-align: left;
}
.p-mv__content-recruit {
  padding-bottom: 0;
}

.p-news {
  background-color: #F7F7F7;
  max-width: 100%;
}
@media screen and (min-width: 769px) {
  .p-news {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-news__inner {
  padding: 6.25rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-news__inner {
    max-width: 56.25rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 6.25rem 1.5625rem;
  }
}

.p-news__title {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-news__title {
    font-size: 3.75rem;
    line-height: 1.1666666667;
    text-align: unset;
  }
}

.p-news__title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .p-news__title span {
    font-size: 1.25rem;
    line-height: 1.6;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .p-news__title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.p-news-content {
  display: block;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-news-content {
    display: flex;
    padding-left: 3.125rem;
  }
}

.p-news-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #A59F9F;
}
@media screen and (min-width: 769px) {
  .p-news-content::after {
    bottom: 0;
  }
}

.p-news__info {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  color: #A59F9F;
  margin-right: 6.5625rem;
}

.p-news__block {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  color: #555;
}

.p-news__btn {
  text-align: center;
}

.p-news__list {
  background-color: #fff;
}

.p-orderSystem__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-orderSystem__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__title {
    font-size: 3.125rem;
  }
}

.p-orderSystem__body-wrap {
  display: block;
  background: linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 75%, transparent 70%, transparent 100%);
  padding-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__body-wrap {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #D9D9D9 0%, #D9D9D9 75%, transparent 70%, transparent 100%);
    padding: 4.75rem 0;
  }
}

.p-orderSystem__top p {
  font-family: "Barlow", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  text-align: left;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__top p {
    font-size: 1rem;
    text-align: center;
  }
}

.p-orderSystem__body {
  background-color: #D9D9D9;
  margin: 0 1.25rem;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__body {
    margin: 0 4.375rem;
    font-size: 1.125rem;
  }
}

.p-orderSystem__body p:first-of-type {
  margin-top: 0;
}

.p-orderSystem__body p:last-of-type {
  margin-top: 2.3125rem;
}

.p-orderSystem__body ol li {
  margin-top: 2.3125rem;
}

.p-orderSystem__img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-orderSystem__img {
    width: 49%;
    margin-top: 0;
  }
}

.p-overview__inner {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.p-overview__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-overview__title {
    font-size: 3.125rem;
  }
}

.p-overview__item {
  font-family: "Barlow", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.1em;
  display: flex;
  padding: 1.875rem 0;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-overview__item {
    font-size: 1rem;
  }
}

.p-overview__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}

.p-overview__item dt {
  width: 30%;
}
@media screen and (min-width: 769px) {
  .p-overview__item dt {
    width: 25%;
  }
}

.p-overview__item dd {
  width: 70%;
}
@media screen and (min-width: 769px) {
  .p-overview__item dd {
    width: 75%;
  }
}

.p-pc-nav {
  display: none;
}
@media screen and (min-width: 769px) {
  .p-pc-nav {
    height: inherit;
    display: block;
  }
}

.p-pc-nav__items {
  height: inherit;
  display: flex;
  align-items: center;
}

.p-pc-nav__item {
  height: inherit;
  position: relative;
  font-family: "'Noto Sans JP', sans-serif";
}

.p-pc-nav__contact {
  background-color: #87C9A3;
  color: #fff;
  width: 9.375rem;
  height: 2.8125rem;
}

.p-pc-nav__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  transition: opacity 0.5s;
}
@media screen and (min-width: 769px) {
  .p-pc-nav__link {
    position: relative;
    padding: 0 1.375rem;
    transition: 1s;
    transform: rotateX(0deg);
  }
}

@media screen and (min-width: 769px) {
  .p-pc-nav__contact a.p-pc-nav__link {
    padding: 0.75rem 1.25rem;
    height: inherit;
    transition: 0.5s;
    transform: unset;
  }
}

.p-pc-nav__contact a.p-pc-nav__link:hover {
  background-color: #13528B;
}

.p-pc-nav__link:hover {
  transition: 1s ease-in-out;
  transform: rotateX(360deg);
}

.p-pc-nav__item--drop {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: opacity 2s ease, visibility 2s ease;
}

.p-pc-nav__items--bottom {
  display: none;
  transition: all 2s ease;
  height: inherit;
  width: 100%;
}

.p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom {
  display: block;
  position: absolute;
  top: 4.6875rem;
  width: 153px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .p-pc-nav__item--drop:hover ul.p-pc-nav__items--bottom {
    bottom: -120px;
  }
}

.p-pc-nav__item--bottom {
  font-size: 0.75rem;
  background-color: #fff;
  opacity: 0.75;
  color: #555;
  height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-pc-nav__item--bottom a {
  height: inherit;
  padding: 0.875rem 1.25rem;
  transition: opacity 0.5s;
  position: relative;
}

.p-pc-nav-bottom__link {
  transition: opacity 0.5s;
  position: relative;
}

.p-pc-nav-bottom__link::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000000;
  bottom: 0.3125rem;
  transform: scale(0, 1);
  transform-origin: center top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
  transition: transform 0.5s; /*変形の時間*/
}

.p-pc-nav-bottom__link:hover::after {
  transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
}

.p-privacy__inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-privacy__inner {
    padding: 1.5625rem;
  }
}

.p-privacy ul {
  margin-top: 1.875rem;
  list-style: decimal;
}

.p-privacy ul li:nth-of-type(n + 2) {
  margin-top: 1.25rem;
}

.p-privacy__lower {
  padding-left: 1.875rem;
}

.p-privacy__lower li:nth-of-type(n + 2),
.p-privacy__lower-bottom li:nth-of-type(n + 2) {
  margin-top: 0;
}

.p-privacy__lower-bottom {
  padding-left: 1.875rem;
}

.p-privacy__end {
  margin-top: 1.875rem;
}

.p-processingMachine__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 769px) {
  .p-processingMachine__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-bottom: 5rem;
  }
}

.p-processingMachine__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5625rem;
}
@media screen and (min-width: 769px) {
  .p-processingMachine__images {
    grid-template-columns: repeat(4, 1fr);
  }
}

.p-processingMachine__img p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}

.p-processingMachine__bottom h3 {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-processingMachine__bottom h3 {
    font-size: 1.25rem;
  }
}

.p-processingMachine__bottom p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-processingMachine__bottom p {
    font-size: 1rem;
    margin-top: 1.25rem;
  }
}

.p-products__inner {
  max-width: 100%;
  width: 100%;
  padding: 0 1.25rem;
  padding-bottom: 5.625rem;
}
@media screen and (min-width: 769px) {
  .p-products__inner {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.5625rem;
    padding-bottom: 7.5rem;
  }
}

.p-products__content {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 769px) {
  .p-products__content {
    display: flex;
    flex-direction: column;
  }
}

.p-products__img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 769px) {
  .p-products__img {
    width: 90%;
    margin-left: 0;
    transform: translateX(-20px);
  }
}

@media screen and (min-width: 1024px) {
  .p-products__title {
    max-width: 560px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-products__body {
  max-width: 630px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.p-products__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-products__body-title {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

.p-products__body-title::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}
@media screen and (min-width: 769px) {
  .p-products__body-title::after {
    bottom: 0;
  }
}

.p-products__body-text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #555;
}

.p-recruit__inner {
  padding: 0 1.25rem;
  padding-bottom: 7.5rem;
  max-width: 100%;
}
@media screen and (min-width: 769px) {
  .p-recruit__inner {
    max-width: 1440px;
    width: 100%;
    padding: 0 3rem;
    padding-bottom: 7.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-recruit__content {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-recruit__content {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-recruit__title {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-recruit__title {
    font-size: 6.5rem;
    line-height: 1.1538461538;
    text-align: unset;
  }
}

.p-recruit__title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .p-recruit__title span {
    font-size: 2.5rem;
    line-height: 1.5;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .p-recruit__title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.p-recruit__body {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .p-recruit__body {
    max-width: 36.8%;
    width: 100%;
    margin-right: auto;
  }
}

.p-recruit__body-title {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6666666667;
  letter-spacing: 0.1em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .p-recruit__body-title {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

.p-recruit__body-title::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #555;
}
@media screen and (min-width: 769px) {
  .p-recruit__body-title::after {
    bottom: 0;
  }
}

.p-recruit__body-text {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #555;
}
@media screen and (min-width: 769px) {
  .p-recruit__body-text {
    font-size: 1rem;
  }
}

.p-recruit__img {
  margin-right: 0;
  width: 100%;
  display: block;
}
@media screen and (min-width: 769px) {
  .p-recruit__img {
    margin-right: 3.125rem;
    width: 49.5%;
  }
}

.p-recruit__img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 713/795;
}

.p-recruit-main {
  background-color: #004020;
}

.p-secondPlant__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-secondPlant__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-secondPlant-sub__title {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-sub__title {
    font-size: 2.5rem;
  }
}

.p-secondPlant-top__body img,
.p-secondPlant-thermosettingProduction__body1 img,
.p-secondPlant-thermosettingProduction__body2 img {
  width: 100%;
}

.p-secondPlant-top__body p,
.p-secondPlant-thermosettingProduction__body1y p,
.p-secondPlant-thermosettingProduction__body2 p {
  width: 100%;
}

.p-secondPlant-top__text {
  margin-top: 1.25rem;
}

.p-secondPlant-moldingMachine__list p {
  margin-top: 0.625rem;
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-moldingMachine__list p {
    font-size: 0.875rem;
  }
}

.p-secondPlant-moldCleaning__body p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-moldCleaning__body p {
    font-size: 1rem;
  }
}

.p-secondPlant-moldCleaning__body p:nth-of-type(2) {
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-moldCleaning__body p:nth-of-type(2) {
    margin-top: 1.25rem;
  }
}

.p-secondPlant-moldCleaning__images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-moldCleaning__images {
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-secondPlant-processingMachine__images {
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-processingMachine__images {
    max-width: 630px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
}

.p-secondPlant-processingMachine__img img {
  height: auto;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-processingMachine__img img {
    height: 100%;
  }
}

.p-secondPlant-cad__body-wrap {
  display: block;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-cad__body-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-secondPlant-cad__body {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-cad__body {
    width: 40%;
  }
}

.p-secondPlant-cad__body h4 {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-cad__body h4 {
    font-size: 1.25rem;
  }
}

.p-secondPlant-cad__body img {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-cad__body img {
    margin-top: 1.875rem;
  }
}

.p-secondPlant-cad__img {
  width: 100%;
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-cad__img {
    width: 44%;
  }
}

.p-secondPlant-measuringInstrument-list {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-measuringInstrument-list {
    width: 68.5%;
  }
}

.p-secondPlant-measuringInstrument__items {
  display: flex;
}

.p-secondPlant-measuringInstrument__item {
  display: block;
}

.p-secondPlant-measuringInstrument__item:nth-of-type(1) {
  margin-right: 6.25rem;
}

.p-secondPlant-measuringInstrument__item:nth-of-type(2) {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .p-secondPlant-measuringInstrument__item:nth-of-type(2) {
    margin-top: 0;
  }
}

.p-secondPlant-measuringInstrument__item img {
  width: 100%;
}

.p-secondPlant-measuringInstrument__item p {
  width: 100%;
}

/*スマホ用のメニュー*/
.p-sp-nav {
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  color: #555;
  background-color: #fff;
  transform: translateX(-120%);
  transition: 0.5s ease;
}
@media screen and (min-width: 1024px) {
  .p-sp-nav {
    display: none;
  }
}

.p-sp-nav.active {
  transform: translateX(0);
}

.p-sp-nav__items {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  text-align: center;
  padding: 3.75rem 0;
  overflow-y: scroll !important;
}

.p-sp-nav__item {
  list-style-type: none;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  transition: 0.5s all;
  font-family: "'Noto Sans JP', sans-serif";
  color: #555;
}

.p-sp-nav__link {
  display: block;
  width: 100%;
  max-width: 100%;
  height: inherit;
  padding: 20px 0;
  transition: opacity 0.5s;
  font-size: 0.875rem;
  line-height: 1.3571428571;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.p-sp-nav__link:hover {
  opacity: 0.6;
}

.p-sp-nav__item:last-child {
  padding: 0;
  margin-top: 1.25rem;
}

.p-sp-nav__body {
  position: relative;
  padding-left: 1.0625rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
}

.p-sp-nav__body::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #DEDEDE;
}

.p-sp-nav__body-link {
  height: inherit;
  display: block;
  padding: 0.3125rem 0;
}

.p-sp-nav__info {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 2.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0.1em;
}

.p-sp-nav__map, .p-sp-nav__policy {
  display: inline-block;
}

.p-sp-nav__map {
  margin-right: 2.5rem;
}

.p-sp-nav__backGround {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden;
  visibility: hidden;
  background-color: #000;
  color: #fff;
  opacity: 0;
}
@media screen and (min-width: 769px) {
  .p-sp-nav__backGround {
    visibility: hidden;
  }
}

.p-sp-nav__backGround.active {
  visibility: visible;
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (min-width: 769px) {
  .p-sp-nav__backGround.active {
    visibility: hidden;
  }
}

#sp-nav.noMve {
  animation: none;
}

.p-sp-nav__contact {
  background-color: #87C9A3;
  color: #fff;
  width: 9.375rem;
  height: 2.8125rem;
  margin-left: auto;
  margin-right: auto;
}

.p-sp-nav__contact a.p-sp-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-works1__inner,
.p-works2__inner {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .p-works1__inner,
  .p-works2__inner {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.p-works1__title,
.p-works2__title {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .p-works1__title,
  .p-works2__title {
    font-size: 3.125rem;
  }
}

.p-works1-top, .p-works2-top {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .p-works1-top, .p-works2-top {
    font-size: 1rem;
  }
}

.p-works1-top h3,
.p-works2-top h3 {
  font-size: 1.125rem;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px solid #333;
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .p-works1-top h3,
  .p-works2-top h3 {
    font-size: 1.25rem;
  }
}

.p-works1-images,
.p-works2-images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
@media screen and (min-width: 769px) {
  .p-works1-images,
  .p-works2-images {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
  }
}

.p-works1-image p,
.p-works2-image p {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .p-works1-image p,
  .p-works2-image p {
    font-size: 1rem;
  }
}

.c-btn {
  font-family: "'Noto Sans JP', sans-serif";
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  font-weight: 400;
  padding-right: 3.125rem;
  position: relative;
  transition: all 0.5s;
}
@media screen and (min-width: 769px) {
  .c-btn {
    font-size: 1rem;
  }
}

.c-btn:hover {
  opacity: 0.6;
}

.c-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #555;
  transition: transform 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform-origin: left;
  -webkit-transform-origin: left;
}

/*矢印の設定*/
.c-btn::after {
  content: url(../images/common/arrow.png);
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 0;
  display: block;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 1rem;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  animation: arrow_to_left 0.5s ease-in-out forwards;
  background-image: url(../images/common/arrow.png);
  display: inline-block;
}

/*線と矢印を繰り返しアニメーション*/
.c-btn:hover::before {
  animation: arrowlong01 2s ease infinite;
}

.c-btn:hover::after {
  animation: arrow_to_right 0.5s ease-in-out forwards;
}

@keyframes arrowlong01 {
  0% {
    width: 0;
    opacity: 0;
  }
  20% {
    width: 0;
    opacity: 1;
  }
  80% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes arrow_to_right {
  30% {
    opacity: 1;
  }
  50% {
    transform: translateX(2rem);
    opacity: 0;
  }
  70% {
    transform: translateX(-2rem);
    opacity: 0;
  }
}
.c-btn__access {
  text-align: center;
}

/*メニューボタン 展開前*/
.c-hamburger {
  display: block;
  position: absolute;
  z-index: 3;
  right: 4.6875%;
  width: 2.5rem;
  height: 1.875rem;
  cursor: pointer;
  text-align: center;
  z-index: 999;
}
@media screen and (min-width: 769px) {
  .c-hamburger {
    display: none;
  }
}

.c-hamburger span {
  display: block;
  position: absolute;
  width: 2.5rem;
  height: 2px;
  left: 0;
  background-color: #555;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.c-hamburger span:nth-of-type(1) {
  top: 0rem;
}

.c-hamburger span:nth-of-type(2) {
  top: 0.9375rem;
}

.c-hamburger span:nth-of-type(3) {
  top: 1.875rem;
}

/*メニューボタン 展開後*/
.c-hamburger.active span:nth-child(1) {
  top: 0.75rem;
  left: 0.375rem;
  background-color: #555;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c-hamburger.active span:nth-child(2),
.c-hamburger.active span:nth-child(3) {
  top: 0.75rem;
  left: 0.375rem;
  background-color: #555;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-logo a {
  display: flex;
  align-items: center;
}

.c-logo__img-left {
  width: 4.875rem;
}

.c-logo__img-right {
  width: 11.25rem;
}

.nav-links {
  clear: both;
  text-align: center;
  margin-top: 4.875rem;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .nav-links {
    margin-top: 6.875rem;
  }
}

.nav-links a, .nav-links span {
  color: #555;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0.0625rem 0.625rem;
  margin: 0 0.3125rem;
  white-space: nowrap;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
}

.nav-links a:first-child {
  margin-left: 0;
}

.nav-links a:last-child {
  margin-right: 0;
}

.nav-links a:hover {
  background-color: #87C9A3;
  opacity: 1;
  border: 0.0625rem solid #87C9A3;
  color: #555;
}

.nav-links span.current {
  background-color: #87C9A3;
  border: 0.0625rem solid #fff;
  color: #555;
}

.nav-links--lower {
  margin-top: 5rem;
}
@media screen and (min-width: 769px) {
  .nav-links--lower {
    margin-top: 6.5625rem;
  }
}

a.page-numbers-lower {
  transition: 0.3s;
  padding: 0 0.75rem;
}
a.page-numbers-lower:hover {
  background-color: #fff;
  color: #555;
}

a.prev-lower {
  margin-right: 3rem;
}

.next-lower {
  margin-left: 2.375rem;
}

@media screen and (min-width: 769px) {
  .wp-pagenavi.nav-links--lower {
    margin-top: 5.8125rem;
  }
}

.wp-pagenavi.nav-links--lower a {
  margin-right: 3rem;
}

.wp-pagenavi.nav-links--lower a:last-child {
  margin-right: 0;
}

.wp-pagenavi.nav-links--lower a:first-child {
  margin-left: 0;
}

.wp-pagenavi-news a, .wp-pagenavi-news span {
  color: #555;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 1.125rem 0.75rem;
  margin: 0 0.3125rem;
  white-space: nowrap;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
}

.wp-pagenavi.wp-pagenavi-news a:hover {
  background-color: #87C9A3;
  color: #fff;
  opacity: 1;
}

.wp-pagenavi.wp-pagenavi-news span.current {
  background-color: #87C9A3;
  color: #fff;
}

.c-title {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #87C9A3;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .c-title {
    font-size: 6.5rem;
    line-height: 1.1538461538;
    text-align: center;
  }
}

.c-title span {
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  color: #13528B;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .c-title span {
    font-size: 2.5rem;
    line-height: 1.5;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .c-title span {
    font-size: 1.875rem;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}

.c-top-arrow {
  right: 20px;
  bottom: 82px;
  position: fixed;
  color: #fff;
  cursor: pointer;
  background-color: #004020;
  padding: 23px;
  padding: 1.4375rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 2000;
}

.c-top-arrow:before {
  content: "";
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  border-top: solid 2px #fff;
  border-top: solid 0.125rem #fff;
  border-right: solid 2px #fff;
  border-right: solid 0.125rem #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  left: 27%;
  top: 40%;
}

.l-about__title {
  margin-right: 0;
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .l-about__title {
    margin-top: -6.25rem;
    margin-right: 1.875rem;
  }
}

.l-about__body {
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-about__body {
    margin-left: auto;
    margin-right: 30px;
  }
}

.l-about__body-text {
  margin-top: 3.125rem;
}

.l-about__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-about__btn {
    margin-top: 4.375rem;
  }
}

.l-about__img {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-about__img {
    margin-top: 0;
  }
}

.l-access {
  margin-top: 4.375rem;
}
@media screen and (min-width: 769px) {
  .l-access {
    margin-top: 9.375rem;
  }
}

.l-access-address {
  margin-top: 3rem;
}

.l-access-tel {
  margin-top: 1.875rem;
}

.l-access-time {
  margin-top: 0.625rem;
}

.l-article {
  margin-top: 7.5rem;
}

.l-article__btn {
  margin-top: 3.75rem;
}

.l-automaticMoldCleaningMachine__content {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-automaticMoldCleaningMachine__content {
    margin-top: 5rem;
  }
}

.l-automaticMoldCleaningMachine-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-automaticMoldCleaningMachine-top {
    margin-top: 1.875rem;
  }
}

.l-automaticMoldCleaningMachine__images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-automaticMoldCleaningMachine__images {
    margin-top: 3.125rem;
  }
}

.l-balloon__items {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-balloon__items {
    margin-top: 5rem;
  }
}

.l-breadcrumb {
  padding-left: 1.25rem;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-breadcrumb {
    padding-left: 6.25rem;
  }
}

.l-breadcrumb-recruit {
  padding-right: 1.25rem;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-breadcrumb-recruit {
    padding-right: 6.25rem;
    margin-left: auto;
  }
}

.l-cadDesign {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-cadDesign {
    margin-top: 5rem;
  }
}

.l-cadDesign__body-wrap {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-cadDesign__body-wrap {
    margin-top: 2.5rem;
  }
}

.l-cadDesign__list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-cadDesign__list {
    margin-top: 1.875rem;
  }
}

.l-center__img {
  margin-top: 7.5rem;
}

.l-entyr-btn {
  margin-top: 5rem;
  margin-bottom: 6.25rem;
}
@media screen and (min-width: 769px) {
  .l-entyr-btn {
    margin-top: 10.5rem;
    margin-bottom: 12.5rem;
  }
}

.l-facility__title {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .l-facility__title {
    margin-top: 3.125rem;
  }
}

.l-facility__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-facility__body {
    margin-top: 3.125rem;
  }
}

.l-facility__body-text {
  margin-top: 3.75rem;
}

.l-facility__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-facility__btn {
    margin-top: 4.375rem;
  }
}

.l-facility__img {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-facility__img {
    margin-top: 0;
  }
}

.l-factory__body__title {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-factory__body__title {
    margin-top: 3.125rem;
  }
}

.l-factory__body-text {
  margin-top: 3.75rem;
}

.l-factory__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-factory__btn {
    margin-top: 4.375rem;
  }
}

.l-factory__img {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-factory__img {
    margin-top: 0;
  }
}

.l-firstPlant {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant {
    margin-top: 3.75rem;
  }
}

.l-firstPlant-top__images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-top__images {
    margin-top: 3.125rem;
  }
}

.l-firstPlant-moldingMachine {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-moldingMachine {
    margin-top: 5rem;
  }
}

.l-firstPlant-moldingMachine__list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-moldingMachine__list {
    margin-top: 2.5rem;
  }
}

.l-firstPlant-moldingMachine__images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-moldingMachine__images {
    margin-top: 3.75rem;
  }
}

.l-firstPlant-processingMachine {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-processingMachine {
    margin-top: 5rem;
  }
}

.l-firstPlant-processingMachine__list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-processingMachine__list {
    margin-top: 2.5rem;
  }
}

.l-firstPlant-processingMachine__images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-processingMachine__images {
    margin-top: 3.75rem;
  }
}

.l-firstPlant-cad {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-cad {
    margin-top: 5rem;
  }
}

.l-firstPlant-cad__body-wrap {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-cad__body-wrap {
    margin-top: 2.5rem;
  }
}

.l-firstPlant-measuringInstrument {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-measuringInstrument {
    margin-top: 5rem;
  }
}

.l-firstPlant-measuringInstrument-list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-measuringInstrument-list {
    margin-top: 2.5rem;
  }
}

.l-firstPlant-measuringInstrument__items {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-firstPlant-measuringInstrument__items {
    margin-top: 3.75rem;
  }
}

.l-footer__inf-title {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-footer__inf-title {
    margin-top: 0;
  }
}

.l-footer__info-post {
  margin-top: 0.9375rem;
}

.l-footer__info-tel {
  margin-top: 0.9375rem;
}

.l-footer__copy {
  padding-bottom: 1.25rem;
}

.l-form__items {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-form__items {
    margin-top: 3.75rem;
  }
}

.l-form__item {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 769px) {
  .l-form__item {
    margin-top: 1.875rem;
  }
}

.l-form__privacy {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-form__privacy {
    margin-top: 2.5rem;
  }
}

.l-form__submit {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-form__submit {
    margin-top: 3.75rem;
  }
}

.l-form-thanks__text {
  margin-top: 9.375rem;
}
@media screen and (min-width: 769px) {
  .l-form-thanks__text {
    margin-top: 19.375rem;
  }
}

.l-greet {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-greet {
    margin-top: 7.5rem;
  }
}

.l-greet__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-greet__body {
    margin-top: 0;
  }
}

.l-greet__body-item {
  margin-top: 1.25rem;
}

.l-greet__name {
  margin-top: 1.875rem;
}

.l-head {
  margin-left: 1.25rem;
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-head {
    margin-left: 4.875rem;
  }
}

.l-inspection {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-inspection {
    margin-top: 5rem;
  }
}

.l-interview {
  padding-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-interview {
    padding-top: 8.75rem;
  }
}

.l-interview__body {
  margin-top: 1.875rem;
}

.l-jobInformation {
  padding-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-jobInformation {
    padding-top: 8.75rem;
  }
}

.l-jobInformation1 {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-jobInformation1 {
    margin-top: 3.75rem;
  }
}

.l-jobInformation2 {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-jobInformation2 {
    margin-top: 7.5rem;
  }
}

.l-mainFacility__title {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-mainFacility__title {
    margin-top: 7.5rem;
  }
}

.l-mainFactory__title {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-mainFactory__title {
    margin-top: 7.5rem;
  }
}

.l-message {
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-message {
    padding-top: 7.5rem;
  }
}

.l-message-top__body-text {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-message-top__body-text {
    margin-top: 3.125rem;
  }
}

.l-message-top__img {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-message-top__img {
    margin-top: 0;
  }
}

.l-mesuringMachine {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-mesuringMachine {
    margin-top: 5rem;
  }
}

.l-mesuringMachine-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-mesuringMachine-top {
    margin-top: 2.5rem;
  }
}

.l-mesuringMachine__body1 {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-mesuringMachine__body1 {
    margin-top: 3.75rem;
  }
}

.l-mesuringMachine__body2 {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-mesuringMachine__body2 {
    margin-top: 3.75rem;
  }
}

.l-mesuringMachine__body-text {
  margin-top: 1.25rem;
}

.l-moldingFactory {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-moldingFactory {
    margin-top: 3.75rem;
  }
}

.l-moldingFactory__block {
  margin-top: 1.25rem;
}

.l-moldingMachine {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-moldingMachine {
    margin-top: 5rem;
  }
}

.l-moldingMachine__img-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-moldingMachine__img-top {
    margin-top: 2.8125rem;
  }
}

.l-moldingMachine__images {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-moldingMachine__images {
    margin-top: 5.625rem;
  }
}

.l-moldUltrasonicCleaningMachine__content {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-moldUltrasonicCleaningMachine__content {
    margin-top: 5rem;
  }
}

.l-moldUltrasonicCleaningMachine-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-moldUltrasonicCleaningMachine-top {
    margin-top: 1.875rem;
  }
}

.l-mv__title-recruit {
  margin-left: 1.875rem;
  margin-top: -5.625rem;
}
@media screen and (min-width: 769px) {
  .l-mv__title-recruit {
    margin-left: 1.875rem;
    margin-top: -12.1875rem;
  }
}

.l-news__contents {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-news__contents {
    margin-top: 3.125rem;
  }
}

.l-news__content {
  padding-left: 0;
  padding: 1.25rem 0;
}
@media screen and (min-width: 769px) {
  .l-news__content {
    padding: 1.875rem 0;
    padding-left: 3.125rem;
  }
}

.l-news__info {
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 769px) {
  .l-news__info {
    margin-bottom: 0;
  }
}

.l-news__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-news__btn {
    margin-top: 3.75rem;
  }
}

.l-news__contents-list {
  margin-top: 0;
}

.l-orderSystem {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-orderSystem {
    margin-top: 7.5rem;
  }
}

.l-orderSystem__top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-orderSystem__top {
    margin-top: 2.5rem;
  }
}

.l-orderSystem__body-wrap {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-orderSystem__body-wrap {
    margin-top: 5rem;
  }
}

.l-overview {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-overview {
    margin-top: 10rem;
  }
}

.l-overview__items {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-overview__items {
    margin-top: 2.875rem;
  }
}

.l-privacy__text,
h2 {
  margin-top: 1.875rem;
}

.l-processingMachine {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-processingMachine {
    margin-top: 5rem;
  }
}

.l-processingMachine-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-processingMachine-top {
    margin-top: 2.5rem;
  }
}

.l-processingMachine__images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-processingMachine__images {
    margin-top: 3.75rem;
  }
}

.l-processingMachine__bottom {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-processingMachine__bottom {
    margin-top: 3.75rem;
  }
}

.l-products__body {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .l-products__body {
    margin-top: 3.75rem;
  }
}

.l-products__img {
  margin-top: 3.75rem;
}

.l-products__body-title {
  margin-top: 3.125rem;
}

.l-products__body-text {
  margin-top: 1.875rem;
}

.l-products__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-products__btn {
    margin-top: 4.375rem;
  }
}

.l-recruit {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-recruit {
    margin-top: 7.5rem;
  }
}

.l-recruit__body {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .l-recruit__body {
    margin-top: 1.875rem;
  }
}

.l-recruit__body__title {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-recruit__body__title {
    margin-top: 3.125rem;
  }
}

.l-recruit__body-text {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-recruit__body-text {
    margin-top: 3.75rem;
  }
}

.l-recruit__btn {
  margin-top: 3.125rem;
}
@media screen and (min-width: 769px) {
  .l-recruit__btn {
    margin-top: 4.375rem;
  }
}

.l-recruit__img {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .l-recruit__img {
    margin-top: 0;
  }
}

.l-secondPlant {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-secondPlant {
    margin-top: 7.5rem;
  }
}

.l-secondPlant-top__body {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-secondPlant-top__body {
    margin-top: 2.5rem;
  }
}

.l-secondPlant-thermosettingProduction,
.l-secondPlant-moldingMachine,
.l-secondPlant-moldCleaning,
.l-secondPlant-processingMachine,
.l-secondPlant-cad,
.l-secondPlant-measuringInstrument {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .l-secondPlant-thermosettingProduction,
  .l-secondPlant-moldingMachine,
  .l-secondPlant-moldCleaning,
  .l-secondPlant-processingMachine,
  .l-secondPlant-cad,
  .l-secondPlant-measuringInstrument {
    margin-top: 5rem;
  }
}

.l-secondPlant-thermosettingProduction__body1,
.l-secondPlant-thermosettingProduction__body2,
.l-secondPlant-moldingMachine__list,
.l-secondPlant-moldCleaning__body,
.l-secondPlant-processingMachine__list,
.l-secondPlant-measuringInstrument-list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-secondPlant-thermosettingProduction__body1,
  .l-secondPlant-thermosettingProduction__body2,
  .l-secondPlant-moldingMachine__list,
  .l-secondPlant-moldCleaning__body,
  .l-secondPlant-processingMachine__list,
  .l-secondPlant-measuringInstrument-list {
    margin-top: 2.5rem;
  }
}

.l-secondPlant-moldCleaning__images,
.l-secondPlant-processingMachine__images,
.l-secondPlant-measuringInstrument__items {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-secondPlant-moldCleaning__images,
  .l-secondPlant-processingMachine__images,
  .l-secondPlant-measuringInstrument__items {
    margin-top: 3.75rem;
  }
}

.l-works1,
.l-works2 {
  margin-top: 5.625rem;
}
@media screen and (min-width: 769px) {
  .l-works1,
  .l-works2 {
    margin-top: 7.5rem;
  }
}

.l-works1-top,
.l-works2-top {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-works1-top,
  .l-works2-top {
    margin-top: 3.75rem;
  }
}

.l-works1__text2,
.l-works1__text3,
.l-works2__text2,
.l-works2__text3 {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .l-works1__text2,
  .l-works1__text3,
  .l-works2__text2,
  .l-works2__text3 {
    margin-top: 1.875rem;
  }
}

.l-works1__text1 p,
.l-works1__text2 p,
.l-works1__text3 p,
.l-works2__text1 p,
.l-works2__text2 p,
.l-works2__text3 p {
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .l-works1__text1 p,
  .l-works1__text2 p,
  .l-works1__text3 p,
  .l-works2__text1 p,
  .l-works2__text2 p,
  .l-works2__text3 p {
    margin-top: 0.9375rem;
  }
}

.l-works1-images,
.l-works2-images {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .l-works1-images,
  .l-works2-images {
    margin-top: 3.75rem;
  }
}