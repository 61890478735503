@use "global" as *;
.l-cadDesign{
    margin-top: rem(40);
    @include mq(md) {
    margin-top: rem(80);
}
}
.l-cadDesign__body-wrap{
    margin-top: rem(20);
    @include mq(md) {
    margin-top: rem(40);
}
}
.l-cadDesign__list{
    margin-top: rem(20);
    @include mq(md) {
        margin-top: rem(30);
    }
}