@use "global" as *;
$drawer-width:rem(40);
$drawer-heigh:2px;
$drawer-color:$black;
/*メニューボタン 展開前*/
.c-hamburger{
    display : block;
    position: absolute;
    z-index : 3;
    right: 4.6875%;
    width : $drawer-width;
    height:rem(30);
    cursor: pointer;
    text-align: center;
    z-index: 999;
    @include mq(md){
        display: none;
    }
    // @media screen and (max-width:425px){
    //   width: rem(18);
    //   height: rem(29);
    //   right: 10%;
    // }

  }
  .c-hamburger  span {
    display : block;
    position: absolute;
    width   : $drawer-width;
    height  :$drawer-heigh;
    left: 0;
    background-color:$black;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition   : 0.3s ease-in-out;
    transition        : 0.3s ease-in-out;
    // @media screen and (max-width:425px){
    //   width: rem(30);
    // }
  
  }
  .c-hamburger  span:nth-of-type(1) {
    top: rem(0);
  
  }
  .c-hamburger  span:nth-of-type(2) {
    top: rem(15);
    // @media screen and (max-width:425px){
    //   top: rem(10);
    // }
  }
  .c-hamburger span:nth-of-type(3) {
    top: rem(30);
    // @media screen and (max-width:425px){
    //   top: rem(20);
    // }
  }
    
  /*メニューボタン 展開後*/
  .c-hamburger.active span:nth-child(1) {
    top : rem(12);
    left: rem(6);
    background-color: $black;
    -webkit-transform: rotate(-45deg);
    -moz-transform   : rotate(-45deg);
    transform        : rotate(-45deg);
   
  }
  .c-hamburger.active span:nth-child(2),
  .c-hamburger.active span:nth-child(3) {
    top: rem(12);
    left: rem(6);
    background-color: $black;
    -webkit-transform: rotate(45deg);
    -moz-transform   : rotate(45deg);
    transform        : rotate(45deg);
    
  }

