@use "global" as *;
.l-firstPlant{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(60);
    }
}
.l-firstPlant-top__images{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(50);
}
}
.l-firstPlant-moldingMachine{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-firstPlant-moldingMachine__list{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(40);
    }

}
.l-firstPlant-moldingMachine__images{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(60);
    }
}
.l-firstPlant-processingMachine{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-firstPlant-processingMachine__list{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(40);
    }
}
.l-firstPlant-processingMachine__images{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(60);
    }
}
.l-firstPlant-cad{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-firstPlant-cad__body-wrap{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(40);
    }
}
.l-firstPlant-measuringInstrument{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-firstPlant-measuringInstrument-list{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(40);
}
}
.l-firstPlant-measuringInstrument__items{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}