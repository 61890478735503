@use "global" as *;
.l-breadcrumb{
    padding-left: rem(20);
    padding-top: rem(30);
    @include mq(md){
    padding-left: rem(100);
    }
}
.l-breadcrumb-recruit{
    padding-right: rem(20);
    padding-top: rem(30);
    @include mq(md){
    padding-right: rem(100);
    margin-left: auto;
    }
}