@use "global" as *;
.l-news__contents{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(50);
}
}
.l-news__content{
    padding-left: 0;
    padding: rem(20) 0;
    @include mq(md){
    padding: rem(30) 0;
    padding-left: rem(50);
}
}
.l-news__info{
    margin-bottom: rem(15);
    @include mq(md){
        margin-bottom: 0;
    }
}
.l-news__btn{
    margin-top: rem(50);
    @include mq(md){
        margin-top: rem(60);
    }
}

// news一覧ページ
.l-news__contents-list{
    margin-top: 0;
}