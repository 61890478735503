@use "global" as *;
.p-greet__inner{
    max-width: 100%;
    width: 100%;
    padding: 0 rem(20);
    @include mq(md){
    max-width: 1100px;
    width: 100%;
    padding: 0 rem(25);
    margin-left: auto;
    margin-right: auto;
    }
}
.p-greet__content-inner{
    display: block;
    @include mq(md){
    display: flex;
    align-items: center;
}
}
.p-greet__img{
    margin-right: 0;
    width: 100%;
    @include mq(md){
    width: 60%;
    margin-right: rem(60);
}
}
.p-greet__body{
    width: 100%;
    @include mq(md){
    width: 50%;
    }
}
.p-greet__body-item{
    max-width: 410px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.p-greet__body-text p{
    font-family: $base-font-family;
    font-size:rem(14);
    line-height: calc(28/16);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size:rem(16);
    }
}
.p-greet__body-text p:nth-of-type(1),
.p-greet__body-text p:nth-of-type(3),
.p-greet__body-text p:nth-of-type(4){   
    font-size: rem(24);
    font-weight: bold;
    text-align: center;
}
.p-greet__body-text ol{
    list-style: decimal;
}
.p-greet__name span{
    font-family: $base-font-family;
    letter-spacing: 0.1em;
}
.p-greet__name span{
    font-size: rem(16);
    line-height: calc(30/20);
    @include mq(md){
    font-size: rem(20);
}
}
.p-greet__name p{
    margin-top: rem(5);
    font-size: rem(30);
    line-height: calc(50/40);
    @include mq(md){
    margin-top: rem(10);
    font-size: rem(40);
}
}
h2.p-greet__title,
h2.p-greet__title span{
    text-align: left;

}

