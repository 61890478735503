@use "global" as *;
.p-automaticMoldCleaningMachine__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
        font-size: rem(30);
        line-height: calc(40/30);
        }
    
}
.p-automaticMoldCleaningMachine-top{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    @include mq(md) {
        font-size: rem(16);
        line-height: calc(24/16);
        }
}
.p-automaticMoldCleaningMachine-top p:nth-of-type(2){
    margin-top: rem(10);
}
.p-automaticMoldCleaningMachine__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(25);
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    }
}
.p-automaticMoldCleaningMachine__img p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    margin-top: rem(10);
}