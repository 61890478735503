@use "global" as *;
.p-moldingMachine__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    border-bottom: 1px solid #000;
    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(80);
}
}
.p-moldingMachine__img-top p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    margin-top: rem(10);
}
.p-moldingMachine__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(25);
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    }
}
.p-moldingMachine__img p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    margin-top: rem(10);
    @include mq(md){
    font-size: rem(10);
    }
}