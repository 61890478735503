@use "global" as *;
h2.p-head{
    font-family:'Inter', sans-serif;
    text-align: left;
    font-size: rem(40);
    line-height: calc(50/40);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(60);
    line-height: calc(70/60);
    }
}
h2.p-head span{
    font-size: rem(18);
    line-height: calc(24/18);
    letter-spacing: 0.05em;
    text-align: left;
    @include mq(md){
    font-size: rem(30);
    line-height: calc(40/30);
    }
    }
