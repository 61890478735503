@use "global" as *;
.p-footer{
    background-color: $gray;
}
.p-footer__inner{
    padding: 3.75rem 0.625rem;
    @include mq(md){
    padding: 10rem 4.375rem 6.25rem 4.5rem;
}
}
    
.p-footer__content{
    display: block;
    @include mq(md){
    display: flex;
    justify-content: space-between;
    align-items: center;
}
}
.p-footer__content-left{
    display: block;
    font-family: $base-font-family;
    color: $black;
    text-align: center;
    @include mq(md){
    display: flex;
}
}
.p-footer__img{
    @include mq(md){
    margin-right: rem(30);
}
}
.p-footer__img img{
    width: rem(150);
    margin-left: auto;
    margin-right: auto;
    @include mq(md){
        width: rem(200);
        height: auto;
        object-fit: cover;
    }
}

.p-footer__info-title{
    font-size: rem(16);
    line-height: calc(24/16);
    letter-spacing: 0.1em;
    @include mq(md){
    font-size: rem(20);
    line-height: calc(32/20);
}
}
.p-footer__info-post,
.p-footer__info-address,
.p-footer__info-tel{
    font-size: rem(14);
    line-height: calc(24/14);
    letter-spacing: 0.1em;
    @include mq(md){
    font-size: rem(16);
    line-height: calc(24/16);
}
}

// フッターナビゲーション
.p-footer__nav{
    display: block;
    @include mq(md){
    display: flex;
    color: $black;
}
}
.p-footer__nav-items1{
    margin-right: 0;
    display: flex;
    justify-content:space-around;
    @include mq(md){
    display: block;
    margin-right: rem(60);
}
}
.p-footer__nav-item{
  padding: rem(10) 0;
}
.p-footer__nav-items--bottom{
    display: none;
    @include mq(md){
    display: block;
    padding-left: rem(15);
    margin-left: rem(20);
    padding-top: rem(15);
    position: relative;
}
}
.p-footer__nav-items--bottom::before{
    content: "";
    position: absolute;
    left: 0;
    width: 1px;
    height: 80%;
    background-color: $black;
}
.p-footer__nav-item--bottom:nth-child(n + 2){
    padding-top: rem(5);
}
.p-footer__nav-items2{
    display: flex;
    justify-content:space-around;
@include mq(md){
    display: block;
}
}
// コピーライト
.p-footer__copy{
    font-family: $base-font-family;
    font-size: rem(14);
    line-height: calc(18/14);
    letter-spacing: 0.1em;
    text-align: center;
}
