@use "global" as *;
.l-message{
    padding-top: rem(40);
    @include mq(md){
    padding-top: rem(120);
}
}
.l-message-top__body-text{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(50);
}
}
.l-message-top__img{
    margin-top: rem(60);
    @include mq(md){
        margin-top: 0;
    }
}