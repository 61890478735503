@use "global" as *;
.l-jobInformation{
    padding-top: rem(90);
    @include mq(md){
    padding-top: rem(140);
}
}
.l-jobInformation1{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}
.l-jobInformation2{
    margin-top: rem(60);
    @include mq(md){
        margin-top: rem(120);
    }
}