@use "global" as *;
.p-breadcrumb__items{
    display: flex;
}
.p-breadcrumb-item a{
    font-family: $second-font-family;
    font-size: rem(14);
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    font-weight: normal;
    padding: 0 rem(10);
}
.p-breadcrumb-item span{
    padding: 0 rem(10);
}

.p-breadcrumb-recruit{
    background-color: $green;
    color: $white;
    display: flex;
    justify-content: end;
}
