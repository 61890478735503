@use "global" as *;
.p-about__inner{
    padding: rem(90) rem(20);
    @include mq(md){
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(95);
    padding-top: rem(100);
    padding-bottom: rem(120);
    } 
}
.p-about__content{
    display: block;
    @include mq(md){
    display: flex;
    align-items: center;
    }
}
.p-about__title{
    text-transform: uppercase;
    writing-mode: horizontal-tb;
    font-family: $second-font-family;
    font-size: rem(50);
    font-weight: bold;
    line-height: calc(70/50);
    letter-spacing: 0.1em;
    color: $ltGreen;
    text-align: center;
    @include mq(md){
    writing-mode: vertical-rl;
    font-size: rem(104);
    line-height: calc(120/104);
    text-align: unset;
}
}
.p-about__body{
    max-width: 100%;
    width: 100%;
    @include mq(md){
    max-width: rem(460);
}
}
.p-about__body-title{
    font-family: $second-font-family;
    font-size: rem(24);
    font-weight: bold;
    line-height: calc(40/24);
    letter-spacing: 0.1em;
    position: relative;
    @include mq(md){
    font-size: rem(50);
    line-height: calc(70/50);
}
}
.p-about__body-title::after{
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
    @include mq(md){
    bottom: 0;
    }
}
.p-about__body-text{
    font-family: $base-font-family;
    font-size: rem(16);
    line-height: calc(32/16);
    letter-spacing: 0.1em;
    color: $black;
}
.p-about__img{
    width: 100%;
    @include mq(md){
    width: 44%;
    margin-left: auto;
    }
}
.p-about__img img{
    aspect-ratio: 600/700;
    @include mq(md){
        aspect-ratio: 640/996;
    }
}