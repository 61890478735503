@use "global" as *;
.p-mainFacility__title{
    font-family:$second-font-family;
    font-size: rem(30);
    font-weight: bold;
    line-height: calc(40/30);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(md){
        font-size: rem(50);
        line-height: calc(60/50);
       
    }
}
