@use "global" as *;
.l-access{
    margin-top: rem(70);
    @include mq(md){
    margin-top: rem(150);
}
}
.l-access-address{
    margin-top: rem(48);
}
.l-access-tel{
    margin-top: rem(30);
}
.l-access-time{
    margin-top: rem(10);
}