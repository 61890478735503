@use "global" as *;
.l-products__body{
    margin-top: 0;
    @include mq(md){
        margin-top: rem(60);
    }
}
.l-products__img{
    margin-top: rem(60);
}
.l-products__body-title{
    margin-top: rem(50);
}
.l-products__body-text{
    margin-top: rem(30);
}
.l-products__btn{
    margin-top: rem(50);
    @include mq(md){
        margin-top: rem(70);
    }
}