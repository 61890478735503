@use "global" as *;
.c-logo a{
    display: flex;
    align-items: center;
}
.c-logo__img-left{
    width: rem(78);
}
.c-logo__img-right{
    width: rem(180);
}