@use "global" as *;
.l-factory__body__title{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(50);
    }
}
.l-factory__body-text{
    margin-top: rem(60);
}
.l-factory__btn{
    margin-top: rem(50);
    @include mq(md){
        margin-top: rem(70);
    }
}
.l-factory__img{
    margin-top: rem(60);
    @include mq(md){
        margin-top: 0;
    }
}