@use "global" as *;
.nav-links {
	clear: both;
	text-align:center;
    margin-top: rem(78);
    text-align: center;
    @include mq(md){
        margin-top: rem(110);
    }
}
.nav-links a, .nav-links span {
	color:$black;
	background-color: $white;
	border: 1px solid $white;
    padding:rem(1) rem(10);
	margin: 0 rem(5);
	white-space: nowrap;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	text-align: center;
	text-decoration: none;
}
.nav-links a:first-child{
    margin-left: 0;
}
.nav-links a:last-child{
    margin-right: 0;
}

.nav-links a:hover{
        background-color:$ltGreen;
        opacity: 1;
        border:rem(1) solid $ltGreen;
        color: $black;
}
.nav-links span.current{
	background-color:$ltGreen;
    border:rem(1) solid $white;
    color: $black;
    }
// .nav-links{
//     margin-top: rem(78);
//     text-align: center;
//     @include mq(md){
//         margin-top: rem(99);
//     }
   
// }


// ブログ詳細ページ
.nav-links--lower{
    margin-top: rem(80);
    @include mq(md){
    margin-top: rem(105);
    }
}
a.page-numbers-lower{
    transition: 0.3s;
    padding: 0 rem(12);
    &:hover{
        background-color:$white ;
        color:$black;
    }
}

a.prev-lower{
    margin-right: rem(48);
}
.next-lower{
    margin-left: rem(38);
}
.wp-pagenavi.nav-links--lower{
    @include mq(md){
    margin-top: rem(93);
}
}
.wp-pagenavi.nav-links--lower a{
    margin-right: rem(48);
}
.wp-pagenavi.nav-links--lower a:last-child{
    margin-right:0;
}
.wp-pagenavi.nav-links--lower a:first-child{
    margin-left:0;
}

// news一覧ページ//
.wp-pagenavi-news a, .wp-pagenavi-news span {
	color:$black;
	background-color: $white;
	border: 1px solid $white;
    padding:rem(18) rem(12);
	margin: 0 rem(5);
	white-space: nowrap;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	text-align: center;
	text-decoration: none;
}
.wp-pagenavi.wp-pagenavi-news a:hover{
    background-color:$ltGreen;
    color: $white;
    opacity: 1;
}
.wp-pagenavi.wp-pagenavi-news span.current{
    background-color:$ltGreen;
    color: $white;
}