@use "global" as *;
.p-mesuringMachine__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    border-bottom: 1px solid #000;
    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(160);
}
}
.p-mesuringMachine__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(30);
    line-height: calc(40/30);
    }
}
.p-mesuringMachine-top{
    width: 100%;
    @include mq(md) {
    width: 60%;
    margin-right: auto;
}
}
.p-mesuringMachine__body-wrapper{
    display: block;
    @include mq(md) {
    display: flex;
}
}
.p-mesuringMachine__body1{
    margin-right: 0;
    @include mq(md) {
    margin-right: rem(100);
    }
}
// .p-mesuringMachine__body1{
//     display: block;
//     @include mq(md) {
//     display: flex;
//     align-items: center;
// }
// }
.p-mesuringMachine__body1 img,
.p-mesuringMachine__body2 img{
    width: 100%;
    height: 100%;
//     @include mq(md) {
//     width: 45%;
//     margin-right: rem(100);
// }
}
.p-mesuringMachine__body1 p{
    margin-top: rem(30);
    @include mq(md) {
        margin-top: 0;
}
}
.p-mesuringMachine__body2 img{
    margin-right: 0;
}
// .p-mesuringMachine__body2{
//     display: block;
//     @include mq(md) {
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
// }
// }
.p-mesuringMachine__body2 p{
    margin-top: rem(30);
    @include mq(md) {
    display:block;
    margin-right: rem(100);
    margin-top: 0;
}
}
.p-mesuringMachine__body1 p,
.p-mesuringMachine__body2 p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(30/16);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(16);
}
}