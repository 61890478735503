@use "global" as *;
.l-form__items{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}
.l-form__item{
    margin-top: rem(15);
    @include mq(md){
        margin-top: rem(30);
    }
}
.l-form__privacy{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(40);
}
}
.l-form__submit{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}
// thanksページ
.l-form-thanks__text{
    margin-top: rem(150);
    @include mq(md){
    margin-top: rem(310);
}
}