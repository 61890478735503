@use "global" as *;
.p-factory__inner{
    padding:0 rem(20);
    padding-bottom: rem(90);
    @include mq(md){
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(25);
    padding-bottom: rem(120);
    }
}
.p-factory__content{
    display: block;
    @include mq(md){
    display: flex;
    align-items: center;
}
}
.p-factory__title{
    text-transform: uppercase;
    font-family: $second-font-family;
    font-size: rem(50);
    font-weight: bold;
    line-height: calc(70/50);
    letter-spacing: 0.1em;
    color: $ltGreen;
    text-align: center;
    @include mq(md){
    font-size: rem(104);
    line-height: calc(120/104);
    text-align: unset;
}
}
.p-factory__title span{
    display: block;
    font-family: $second-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(36/20);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(lg){
        font-size: rem(40);
        line-height: calc(60/40);
        text-align:left;
    }
        @media screen and (min-width:769px) and (max-width:1023px){
            font-size: rem(30);
            max-width: 480px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
        }
}
.p-factory__body{
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    @include mq(md){
    max-width: 38.8%;
    width: 100%;
    margin-left: auto;
    margin-right: rem(75);
}
}
.p-factory__body-title{
    font-family: $second-font-family;
    font-size: rem(24);
    font-weight: bold;
    line-height: calc(40/24);
    letter-spacing: 0.1em;
    position: relative;
    @include mq(md){
    font-size: rem(50);
    line-height: calc(70/50);
}
}
.p-factory__body-title::after{
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
    @include mq(md){
    bottom: 0;
    }
}
.p-factory__body-text{
    font-family: $base-font-family;
    font-size: rem(16);
    line-height: calc(32/16);
    letter-spacing: 0.1em;
    color: $black;
}
.p-factory__img{
    margin-right: 0;
    width: 100%;
    display: block;
    @include mq(md){
    width: 50%;
    transform: translateX(20px);
    }
}
.p-factory__img img{
    width: 100%;
    height: 100%;
    aspect-ratio: 720/792;
}