@use "global" as *;
.l-processingMachine{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
    }
}
.l-processingMachine-top{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(40);
}
}
.l-processingMachine__images{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}
.l-processingMachine__bottom{
    margin-top: rem(30);
    @include mq(md){ 
    margin-top: rem(60);
}
}