@use "global" as *;
.p-message-top__inner{
    max-width: rem(1160);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq(md){
       padding-left: rem(25);
       padding-right: rem(25);
    }
}
.p-message-top__content{
    display: block;
    @include mq(md){
    display: flex;
}
}
.p-message-top__body{
    width: 100%;
    margin-right: 0;
    color: $white;
    @include mq(md){
    width: 40%;
    margin-right: rem(140);
}
}
.p-message-top__body-title{
    font-family: $second-font-family;
    font-size: rem(30);
    line-height: calc(70/50);
    letter-spacing: 0.05em;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    display: inline-block;
    @include mq(md){
    font-size: rem(42);
}
}
.p-message-top__body-text p{
    font-family: $base-font-family;
    font-size: rem(14);
    line-height: calc(32/16);
    letter-spacing: 0.01em;
    @include mq(md){
    font-size: rem(16);
}
}
.p-message-top__img{
    width: 100%;
    @include mq(md){
    width: 50.8%;
}
}
.p-message-top__img p{
    text-align: right;
    font-size: rem(20);
    line-height: calc(30/18);
    letter-spacing: 0.05em;
    color: $white;
}