@use "global" as *;
.p-privacy__inner{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: rem(20);
    @include mq(md){
    padding: rem(25);
    }
}
.p-privacy ul{
    margin-top: rem(30);
    list-style:decimal;
}
.p-privacy ul li:nth-of-type(n + 2){
    margin-top: rem(20);
}
.p-privacy__lower{
    padding-left: rem(30);
}
.p-privacy__lower li:nth-of-type(n + 2),
.p-privacy__lower-bottom li:nth-of-type(n + 2){
    margin-top: 0;
}
.p-privacy__lower-bottom{
    padding-left: rem(30);
}
.p-privacy__end{
    margin-top: rem(30);
}