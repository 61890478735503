@use "global" as *;
.l-footer__inf-title{
    margin-top: rem(20);
    @include mq(md){
        margin-top: 0;
    }
}
.l-footer__info-post{
    margin-top: rem(15);
}
.l-footer__info-tel{
    margin-top: rem(15);
}
.l-footer__copy{
    padding-bottom: rem(20);
}