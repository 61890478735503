@use "global" as *;
.l-moldUltrasonicCleaningMachine__content{
    margin-top: rem(40);
    @include mq(md) {
    margin-top: rem(80);
    }
}
.l-moldUltrasonicCleaningMachine-top{
    margin-top: rem(20);
    @include mq(md) {
    margin-top: rem(30);
}
}