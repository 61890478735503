@use "global" as *;

.p-processingMachine__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    border-bottom: 1px solid #000;
    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(80);
}
}
.p-processingMachine__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(25);
    @include mq(md) {
    grid-template-columns: repeat(4,1fr);
}
}
.p-processingMachine__img p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.05em;
    margin-top: rem(10);
}
.p-processingMachine__bottom h3{
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.05em;
    @include mq(md) {
    font-size: rem(20);
}
}
.p-processingMachine__bottom p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    margin-top: rem(10);
    @include mq(md) {
    font-size: rem(16);
    margin-top: rem(20);
}
}