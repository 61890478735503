@use "global" as *;
.p-inspection__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(80);
}
}
.p-inspection__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(30);
    line-height: calc(40/30);
    }
}
.p-inspection__text{
    font-family: $base-font-family;
    font-size: rem(16);
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    color: #000;
}