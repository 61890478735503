@use "global" as *;
.p-firstPlant__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(30);
    border-bottom: 1px solid #000;
    @include mq(md){
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(180);
    }
}
.p-firstPlant-sub__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(50/40);
    letter-spacing: 0.1em;
    @include mq(md){
    font-size: rem(40);
}
}
.p-firstPlant-top p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(30/14);
    letter-spacing: 0.05em;
    text-align: left;
    margin-top: rem(20);
    @include mq(md){
    font-size: rem(16);
    text-align: center;
    max-width: rem(950);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(40);
}
}
.p-firstPlant-top__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(20);
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    gap: rem(25);
}
}
.p-firstPlant-moldingMachine__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(20);
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    gap: rem(25);
}
}
.p-firstPlant-processingMachine__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: rem(20);
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    gap: rem(25);
}
}
.p-firstPlant-cad__body-wrap{
    display: block;
    @include mq(md){
    display: flex;
   justify-content: space-between;
   align-items: center;
}
}
.p-firstPlant-cad__body{
    width: 100%;
    @include mq(md){
    width: 40%;
}
}
.p-firstPlant-cad__body h4{
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight: 400;
    line-height: calc(20/20);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(20);
}
}
.p-firstPlant-cad__body img{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(30);
}
}
.p-firstPlant-cad__img{
    width: 100%;
    @include mq(md){
    width: 44%;
}
}
.p-firstPlant-measuringInstrument-list{
    width: 100%;
    @include mq(md){
    width: 68.5%;
}
}
.p-firstPlant-measuringInstrument__items{
    display: block;
    @include mq(md){
    display: flex;
}
}
.p-firstPlant-measuringInstrument__item{
    display: block;
    width: 100%;
    @include mq(md){
    width: 45%;
}
}
.p-firstPlant-measuringInstrument__item:nth-of-type(1){
    margin-right: 0;
    @include mq(md){
    margin-right: rem(100);
}
}
.p-firstPlant-measuringInstrument__item:nth-of-type(2){
    margin-top: rem(30);
    @include mq(md){
    margin-top: 0;
}
}
.p-firstPlant-measuringInstrument__item img{
    width: 100%;
    height: 100%;

}
.p-firstPlant-measuringInstrument__text{
    width: 100%;
    margin-top: rem(20);
}
