@use "global" as *;
.p-feature__content{
    position: relative;
    height: rem(370);
    display: block;
}
.p-feature__content img{
    height: 68%;
    @include mq(md){
    height: 100%;
}
}
.p-feature__content h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: $base-font-family;
    font-size: rem(30);
    line-height: calc(60/50);
    letter-spacing: 0.05em;
    color: $white;
    @include mq(md){
        font-size: rem(50);
    }
}
h2.p-feature__title span{
    text-align: left!important;
    font-family: $base-font-family;
    font-size: rem(16);
    line-height: calc(30/20);
    letter-spacing: 0.05em;
    color: $white;
    @include mq(md){
        font-size: rem(20);
}
}