@use "global" as *;
    .c-title{
    text-transform: uppercase;
    font-family: $second-font-family;
    font-size: rem(50);
    font-weight: bold;
    line-height: calc(70/50);
    letter-spacing: 0.1em;
    color: $ltGreen;
    text-align: center;
    @include mq(md){
    font-size: rem(104);
    line-height: calc(120/104);
    text-align: center;
}
}
.c-title span{
    display: block;
    font-family: $second-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(36/20);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(lg){
    font-size: rem(40);
    line-height: calc(60/40);
    text-align:left;
}
    @media screen and (min-width:769px) and (max-width:1023px){
        font-size: rem(30);
        max-width: 480px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }
}