@use "global" as *;
.c-top-arrow{
    right: 20px;
    bottom: 82px;
    position: fixed;
    color: $white;
    cursor: pointer;
    background-color: $green;
    padding: 23px;
    padding: 1.4375rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 2000;
}
.c-top-arrow:before {
    content: "";
    width: 20px;
    width: 1.25rem;
    height: 20px;
    height: 1.25rem;
    border-top: solid 2px $white;
    border-top: solid 0.125rem $white;
    border-right: solid 2px $white;
    border-right: solid 0.125rem $white;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    left: 27%;
    top: 40%;
}