@use "global" as *;
.p-jobInformation__inner{
    max-width: 1250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(60);
    @include mq(md){
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(120);
    }
}
.p-jobInformation__inner h2{
    font-family: $second-font-family;
    font-size: rem(30);
    line-height: calc(80/70);
    letter-spacing: 0.2em;
    color: $white;
    text-align: center;
    @include mq(md){
    font-size: rem(70);
}
}
.p-jobInformation1,
.p-jobInformation2{
    background-color: $white;
}
.p-jobInformation1__inner,
.p-jobInformation2__inner{
    max-width: 1040px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: rem(30) ;
    @include mq(md){
    padding:rem(60) rem(100);
}
}
.p-jobInformation1__title{
    font-family: $second-font-family;
    font-size: rem(20);
    line-height: calc(40/32);
    letter-spacing: 0.05em;
    color: $black;
    @include mq(md){
    font-size: rem(32);
}
}
.p-jobInformation1__items,
.p-jobInformation2__items{
    margin-top: rem(40);
}
.p-jobInformation1__item,
.p-jobInformation2__item{
    display: flex;
    padding: rem(30) 0;
    border-top: 1px solid $black;
    font-family: $base-font-family;
    font-size: rem(10);
    line-height: calc(24/15);
    letter-spacing: 0.05em;
    color: $black;
    @include mq(md){
    font-size: rem(15);
}
}
.p-jobInformation1__item:last-of-type,
.p-jobInformation2__item:last-of-type{
    border-bottom: 1px solid $black;
}
.p-jobInformation1__item dt{
    width: 25%;
    padding-left: rem(10);
    @include mq(md){
    width: 20%;
    padding-left: rem(50);
}
}