@use "global" as *;
.p-orderSystem__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq(md){
    padding-left: rem(25);
    padding-right: rem(25);
    }
}
.p-orderSystem__title{
    font-family:$second-font-family;
    font-size: rem(30);
    font-weight: bold;
    line-height: calc(60/50);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(md){
    font-size: rem(50);
}
}
.p-orderSystem__body-wrap{
    display: block;
    background: linear-gradient(180deg, #D9D9D9 0%,#D9D9D9 75%,transparent 70%, transparent 100%);
    padding-top: rem(50);
    @include mq(md){
    display: flex;
    // justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #D9D9D9 0%,#D9D9D9 75%,transparent 70%, transparent 100%);
    padding: rem(76) 0;
}
}

.p-orderSystem__top p{
    font-family: $second-font-family;
    font-size: rem(14);
    font-weight: normal;
    line-height: calc(30/18);
    letter-spacing: 0.1em;
    text-align: left;
    @include mq(md){
    font-size: rem(16);
    text-align: center;
    }
}
.p-orderSystem__body{
    background-color: #D9D9D9;
    margin: 0 rem(20);
    font-family: $second-font-family;
    font-size: rem(16);
    font-weight: bold;
    line-height: calc(24/18);
    letter-spacing: 0.1em;
    @include mq(md){
    margin: 0 rem(70);
    font-size: rem(18);
    }
}
.p-orderSystem__body p:first-of-type{
    margin-top: 0;
}
.p-orderSystem__body p:last-of-type{
    margin-top: rem(37);
}
.p-orderSystem__body ol li{
    margin-top: rem(37);
}
.p-orderSystem__img{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(30);
    @include mq(md){
    width: 49%;
    margin-top: 0;
}
}