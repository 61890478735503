@use "global" as *;
.p-header{
    height:75px;
    background-color: $white;
}

.p-header__inner{
    padding: 0 rem(36) 0 rem(20);
    max-width:100%;
    width: 100%;
    height: inherit;
    margin: 0 auto;
    @include mq(md){
        padding: 0 rem(20);
    }
}
.p-header__content{
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
}

/*==ふわっと出現させるためのCSS*/

/*　上に上がる動き　*/

#header.UpMove{
    position: fixed;
    width:100%;
    animation: UpAnime 0.5s forwards;
  }
  
  @keyframes UpAnime{
    from {
      opacity: 1;
    transform: translateY(0);
    }
    to {
      opacity: 0;
    transform: translateY(-100px);
    }
  }
  
  /*　下に下がる動き　*/
  
  #header.DownMove{
    position: fixed;
    width:100%;
    animation: DownAnime 0.5s forwards;
  }
  @keyframes DownAnime{
    from {
      opacity: 0;
    transform: translateY(-100px);
    }
    to {
      opacity: 1;
    transform: translateY(0);
    }
  }
