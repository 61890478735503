@use "global" as *;
.p-balloon__items{
    max-width:100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq (md){
    max-width: 970px;
    padding-left: rem(25);
    padding-right: rem(25);
}
}
.p-balloon__item{
    display: flex;
    align-items: center;
    background-color:$green;
    padding: rem(30) rem(40);
    position: relative;
}
.p-balloon__item:nth-of-type(1):before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 30px solid transparent;
    border-top: 40px solid $green;
}
.p-balloon__item:nth-of-type(n + 2){
    margin-top: rem(60);
}
.p-stepCircle{
    width: rem(100);
    margin-right: rem(50);
}
.p-balloon__item p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: normal;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    color: $white;
    @include mq (md){
        font-size: rem(16);
    }
}
