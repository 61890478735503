@use "global" as *;
.p-moldUltrasonicCleaningMachine__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
        font-size: rem(30);
        line-height: calc(40/30);
}
}
.p-moldUltrasonicCleaningMachine__body{
    display: block;
    @include mq(md) {
    display: flex;
    justify-content: space-between;
}
}
.p-moldUltrasonicCleaningMachine-top{
    width: 100%;
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight: 400;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    @include mq(md) {
    width: 46.5%;
}
}
.p-moldUltrasonicCleaningMachine-top p:nth-of-type(3){
    margin-top: rem(30);
}
.p-moldUltrasonicCleaningMachine__img{
    width: 100%;
    margin-top: rem(30);
    @include mq(md) {
    width: 33%;
    margin-top: 0;
}
}