@use "global" as *;
.p-products__inner{
    max-width: 100%;
    width: 100%;
    padding: 0 rem(20);
    padding-bottom: rem(90);
    @include mq(md){
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 rem(25);
    padding-bottom: rem(120);
}
}
.p-products__content{
    display: flex;
    flex-direction: column-reverse;
    @include mq(md){
        display: flex;
        flex-direction: column;
    }
}
.p-products__img{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include mq(md){
        width: 90%;
        margin-left: 0;
        transform: translateX(-20px);
    }
}
.p-products__title{
    @include mq(lg){
    max-width:560px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

}
.p-products__body{
max-width: 630px;
width: 100%;
margin-right: auto;
margin-left: auto;
}
.p-products__body-title{
    font-family: $second-font-family;
    font-size: rem(24);
    font-weight: bold;
    line-height: calc(40/24);
    letter-spacing: 0.1em;
    position: relative;
    @include mq(md){
    font-size: rem(50);
    line-height: calc(70/50);
}
}
.p-products__body-title::after{
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
    @include mq(md){
    bottom: 0;
    }
}
.p-products__body-text{
    font-family: $base-font-family;
    font-size: rem(16);
    line-height: calc(32/16);
    letter-spacing: 0.1em;
    color: $black;
}
