@use "global" as *;
$drawer-width:rem(40);
$drawer-heigh:rem(2);
$drawer-color:$black;
/*スマホ用のメニュー*/
.p-sp-nav {
    width: 100%;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    color: $black;
    background-color:$white;
    transform: translateX(-120%);
    transition: 0.5s ease;
@include mq(lg) {
    display: none;
}
}
.p-sp-nav.active {
    transform: translateX(0);
}
.p-sp-nav__items{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0 auto;
    text-align: center;
    padding: rem(60) 0;
    overflow-y: scroll !important;
}
.p-sp-nav__item{
    list-style-type: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
    transition: .5s all;
    font-family:$base-font-family;
    color:$black;
}
.p-sp-nav__link{
    display: block;
    width:100%;
    max-width :100%;
    height: inherit;
    padding: 20px 0;
    transition: opacity 0.5s;
    font-size: rem(14);
    line-height: calc(19/14);
    font-weight: 700;
    letter-spacing: 0.1em;
}
.p-sp-nav__link:hover{
    opacity: 0.6;
}
.p-sp-nav__item:last-child{
    padding:0;
    margin-top: rem(20);
}
.p-sp-nav__body{
    position: relative;
    padding-left: rem(17);
    font-size: rem(12);
    font-weight: 400;
    line-height: calc(16/12);
    letter-spacing: 0.1em;
}
.p-sp-nav__body::before{
    content: "";
    width: 1px;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #DEDEDE;
}
.p-sp-nav__body-link{
    height: inherit;
    display: block;
    padding: rem(5) 0;
   
}
.p-sp-nav__info{
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0 rem(40);
    font-size: rem(12);
    font-weight: 400;
    line-height: calc(16/12);
    letter-spacing: 0.1em;
}
// .p-sp-nav__info::before{
//     content: "";
//     width: 63.46%;
//     height: 1px;
//     position: absolute;
//     top: rem(-20);
//     left: 50%;
//     transform: translate(-50%);
//     background-color: #DEDEDE;
// }
.p-sp-nav__map,.p-sp-nav__policy{
    display: inline-block;
}
.p-sp-nav__map{
    margin-right: rem(40);
}
// drawerオープン時背景設定
.p-sp-nav__backGround{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    overflow: hidden;
    visibility: hidden;
    background-color: #000;
    color: #fff;
    opacity: 0;
    @include mq(md){
        visibility: hidden;
    }
}
.p-sp-nav__backGround.active{
    visibility: visible;
    opacity: 0.4;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    @include mq(md){
        visibility: hidden;
    }
}

// スクロール時のアニメーションから外す
#sp-nav.noMve{
    animation: none;
  }
  .p-sp-nav__contact{
    background-color:#87C9A3;
    color: #fff;
    width: rem(150);
    height: rem(45);
    margin-left: auto;
    margin-right: auto;
}
  .p-sp-nav__contact a.p-sp-nav__link{
    display: flex;
    align-items: center;
    justify-content: center;
}