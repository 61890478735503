@use "global" as *;

.p-moldingFactory__inner {
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    border-bottom: 1px solid #000;

    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(80);
    }
}

.p-moldingFactory__title {
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(30);
    line-height: calc(40/30);
    }
}

.p-moldingFactory__block h3 {
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight: 400;
    line-height: calc(24/16);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(20);
    line-height: calc(40/20);
}
}
.p-moldingFactory__block p {
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(20/14);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(18);
    font-weight: 400;
    line-height: calc(36/18);
}
}
.p-moldingFactory__block p:nth-of-type(2) {
    margin-top: rem(12);
}