@use "global" as *;
.l-about__title{
    margin-right: 0;
    margin-top: 0;
    @include mq(md){
    margin-top: rem(-100);
    margin-right: rem(30);
}
}
.l-about__body{
    margin-left: 0;
    margin-right: 0;
    margin-top: rem(30);
    @include mq(md){
    margin-left: auto;
    margin-right: 30px;
}
}
.l-about__body-text{
    margin-top: rem(50);
}
.l-about__btn{
    margin-top: rem(50);
    @include mq(md){
        margin-top: rem(70);
    }
}
.l-about__img{
    margin-top: rem(60);
    @include mq(md){
       margin-top: 0; 
    }
}