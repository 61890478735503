@use "global" as *;
.p-overview__inner{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(25);
    padding-right: rem(25);
}
.p-overview__title{
    font-family:$second-font-family;
    font-size: rem(30);
    font-weight: bold;
    line-height: calc(60/50);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(md){
    font-size: rem(50);
}
}
.p-overview__item{
    font-family: $second-font-family;
    font-size: rem(12);
    font-weight:400;
    line-height: calc(28/16);
    letter-spacing: 0.1em;
    display: flex;
    padding: rem(30) 0;
    position: relative;
    @include mq(md){
    font-size: rem(16);
    }
}
.p-overview__item::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
}
.p-overview__item dt{
    width: 30%;
@include mq(md){
    width: 25%;
}
}
.p-overview__item dd{
    width: 70%;
    @include mq(md){
        width: 75%;
}
}