@use "global" as *;
.p-form__inner{
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(100);
    @include mq(md){
    padding-left: rem(20);
    padding-right: rem(20);
}
}
.p-form-top__text{
    text-align: left;
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: normal;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    @include mq(md){
    text-align: center;
    font-size: rem(16);
}
}
.p-form__item{
    display: block;
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: normal;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
@include mq(md){
    display: flex;
    justify-content: center;
    font-size: rem(16);
}
}
.p-form__item dt{
    width: 100%;
    @include mq(md){
    width: 20%;
}
}
.p-form__item dd{
    width: 100%;
    border:1px solid #C2BFBF;
    margin-top: rem(5);
    padding: rem(8);
    @include mq(md){
    width: 50%;
    margin-top: 0;
}
}
.p-form__item textarea{
    width: 100%;
    padding: rem(8);
}
.p-form__privacy{
    max-width: 100%;
    width: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    @include mq(md){
    max-width: 300px;
    white-space: nowrap;
}
}
.p-form__privacy p,
.p-form__privacy label{
    font-family: $base-font-family;
    font-size: rem(12);
    font-weight: normal;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(16);
    }
}
.p-form__privacy a{
    text-decoration: underline;
}
.p-form__privacy label{
    display: flex;
    align-items: center;
    margin-top: rem(10);
}
.wpcf7-list-item{
    margin: 0;
}
.wpcf7-list-item input{
    display: block;
    appearance: auto;
    width: rem(20);
    height: rem(20);
    transform: translateY(8px);
}

// .p-form__submit,
// .p-form-thanks__btn,
// .p-form__submit-back{
//     width: rem(120);
//     padding: rem(14) rem(43);
//     background-color: $ltGreen;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: $white;
//     transition: opacity 0.5s;
//     margin-top: rem(50);
//     cursor: pointer;
//     @include mq(md){
//     margin-left: auto;
//     margin-right: auto;
// }
// }
.btn_contact7 input{
    width: rem(120);
    padding: rem(14) rem(43);
    background-color: $ltGreen;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    transition: opacity 0.5s;
    margin-top: rem(50);
    cursor: pointer;
    @include mq(md){
    margin-left: auto;
    margin-right: auto;
}
}
.p-form-thanks__btn{
    width: rem(200);
    margin-left: auto;
    margin-right: auto;
}
.p-form__submit:hover,
.p-form-thanks__btn:hover,
.p-form__submit-back:hover{
    opacity: 0.6;
}
.box__checked {
    text-align: center;
    margin-bottom: 48px;
    & .form-item {
      margin-bottom: 28px;
    }
  }
  .p-form__checkbox {
    opacity: 0;
  }
  
  .privacy-text {
    position: relative;
    padding-left: rem(30);
  
    &::before {
      content: "";
      position: absolute;
      width: rem(18);
      height: rem(18);
      display: inline-block;
      left: 0;
      background-color:$white;
      border: 1px solid #707070;
    }
    
  }
  
  .p-form__checkbox:checked + .privacy-text::after{
    content: "";
      width: 4px;
      height: 8px;
      border-right: 2px solid #1B224C;
      border-bottom: 2px solid #1B224C;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 20%;
      left: 2.5%;
  }
  
//   確認ページ
.p-form-confirm__wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-form__submit-confirm{
    margin-right: rem(30);
    margin-left: 0;
}
.p-form__submit-back{
    color: $ltGreen;
    background-color: $white;
    border: 1px solid $ltGreen;
}









/* Contact Form7 */

.contact7 {
	max-width: 100%;
	box-sizing: border-box;
}
.contact7 dd {
	margin: 0 0 30px 0;
	background-color:#FFF;
}
.contact7 dt {
	font-weight: normal;
}

.btn_contact7 {
	text-align: center;
}
.btn_contact7 input {
	width: 70%;
	background-color: #005513;
	color: #FFF;
	font-size: 1.1em;
	font-weight: bold;
	letter-spacing: 0.2em;
	border: 1px solid #005513;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	-ms-transition: 0.3s;
	transition: 0.3s;	
}
.btn_contact7 input:hover {
	background-color: #FFF;
	color: #005513; 
}
@media screen and (min-width: 768px) {
.contact7 dt {
	float: left;
	clear: left;
	width: 35%;
	padding: 5px 10px 5px 0;
	}
.contact7 dd {
	margin-left: 35%;
	}

.btn_contact7 input {
	width: 70%;
	background-color: #005513;
	color: #FFF;
	font-size: 1.1em;
	font-weight: bold;
	letter-spacing: 0.2em;
	border: 1px solid #005513;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	-ms-transition: 0.3s;
	transition: 0.3s;	
}
.btn_contact7 input:hover {
	background-color: #FFF;
	color: #005513; 
}
}
