@use "global" as *;
.p-secondPlant__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq(md){
    padding-left: rem(25);
    padding-right: rem(25);
    }
}
.p-secondPlant-sub__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(50/40);
    letter-spacing: 0.1em;
    @include mq(md){
    font-size: rem(40);
}
}
// .p-secondPlant-top__body,
// .p-secondPlant-thermosettingProduction__body2{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }
.p-secondPlant-top__body img,
.p-secondPlant-thermosettingProduction__body1 img,
.p-secondPlant-thermosettingProduction__body2 img{
    width: 100%;
}
.p-secondPlant-top__body p,
.p-secondPlant-thermosettingProduction__body1y p,
.p-secondPlant-thermosettingProduction__body2 p{
    width: 100%;
}
.p-secondPlant-top__text{
margin-top: rem(20);
}
// .p-secondPlant-thermosettingProduction__body1{
//     display: flex;
//     justify-content: space-between;
//     flex-direction: row-reverse;
//     align-items: center;
// }
.p-secondPlant-moldingMachine__list p{
    margin-top: rem(10);
    font-family: $base-font-family;
    font-size: rem(12);
    font-weight: 400;
    line-height: calc(14/14);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(14);
    }
}
.p-secondPlant-moldCleaning__body p{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(24/16);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(16);
    }
}
.p-secondPlant-moldCleaning__body p:nth-of-type(2){
    margin-top: rem(10);
    @include mq(md){
    margin-top: rem(20);
}
}
.p-secondPlant-moldCleaning__images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 25px;
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
}
}
.p-secondPlant-processingMachine__images{
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 25px;
    @include mq(md){
    max-width: 630px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
}
}
.p-secondPlant-processingMachine__img img{
    height: auto;
    @include mq(md){
    height: 100%;
}
}
.p-secondPlant-cad__body-wrap{
    display: block;
    @include mq(md){
    display: flex;
   justify-content: space-between;
   align-items: center;
}
}
.p-secondPlant-cad__body{
    width: 100%;
    @include mq(md){
    width: 40%;
}
}
.p-secondPlant-cad__body h4{
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight: 400;
    line-height: calc(20/20);
    letter-spacing: 0.05em;
    @include mq(md){
    font-size: rem(20);
}
}
.p-secondPlant-cad__body img{
    margin-top: rem(20);
    @include mq(md){
        margin-top: rem(30);
}
}
.p-secondPlant-cad__img{
    width: 100%;
    margin-top: rem(20);
    @include mq(md){
    width: 44%;
}
}
.p-secondPlant-measuringInstrument-list{
    width: 100%;
    @include mq(md){
    width: 68.5%;
}
}
.p-secondPlant-measuringInstrument__items{
    display: flex;
}
.p-secondPlant-measuringInstrument__item{
    display: block;
}
.p-secondPlant-measuringInstrument__item:nth-of-type(1){
    margin-right:rem(100);
}
.p-secondPlant-measuringInstrument__item:nth-of-type(2){
    margin-top: rem(30);
    @include mq(md){
    margin-top: 0;
}
}
.p-secondPlant-measuringInstrument__item img{
    width: 100%;
}
.p-secondPlant-measuringInstrument__item p{
    width: 100%;
}