@use "global" as *;
.l-orderSystem{
    margin-top: rem(90);
    @include mq(md){
    margin-top: rem(120);
}
}
.l-orderSystem__top{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(40);
}
}
.l-orderSystem__body-wrap{
    margin-top: rem(40);
    @include mq(md){
        margin-top: rem(80);
}
}