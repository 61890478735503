@use "global" as *;
.p-mv__content{
    padding-bottom: rem(90);
    background-color:$green;
}
.p-mv__body{
    position: relative;
}
.p-mv__body img{
    width: 90%;
    margin-left: auto;
    height: 100vh; 
    aspect-ratio: 768/967;
}
.p-mv__body-recruit img{
    height: auto;
    @include mq(md){
    height: 100vh;
}
}
.p-mv__title{
    position: absolute;
    bottom:15%;
    left: 15%;
    font-family: $base-font-family;
    font-size:rem(20);
    line-height: calc(48/32);
    letter-spacing: 0.1em;
    font-weight: 400;
    color: $white;
    @include mq(md){
    font-size:rem(60);
    line-height: calc(80/60);
    font-weight: bold;
    }
}
h2.p-mv__title-recruit{
    text-align: left;
    font-size: rem(80);
    color: $white;
    letter-spacing: 0;
    position: relative;
    @include mq(md){
    font-size: rem(200);
}
}

h2.p-mv__title-recruit span{
    font-family: $second-font-family;
    color: $white;
    text-align: left;
    @include mq(md){
}
}
.p-mv__content-recruit{
    padding-bottom: 0;
}