@use "global" as *;
.p-news{
    background-color: $gray;
    max-width: 100%;
    @include mq(md){
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}
}
.p-news__inner{
    padding: rem(100) rem(20);
    @include mq(md){
        max-width: rem(900);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: rem(100) rem(25);
    }
}
.p-news__title{
    text-transform: uppercase;
    font-family: $second-font-family;
    font-size: rem(40);
    font-weight: bold;
    line-height: calc(60/40);
    letter-spacing: 0.1em;
    color: $ltGreen;
    text-align: center;
    @include mq(md){
    font-size: rem(60);
    line-height: calc(70/60);
    text-align: unset;
}
}
.p-news__title span{
    display: block;
    font-family: $second-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(36/20);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(lg){
        font-size: rem(20);
        line-height: calc(32/20);
        text-align:left;
    }
        @media screen and (min-width:769px) and (max-width:1023px){
            font-size: rem(30);
            max-width: 480px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
        }
}
.p-news-content{
    display: block;
    position: relative;

    @include mq(md){
    display: flex;
    padding-left: rem(50);
}
}
.p-news-content::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color:#A59F9F;
    @include mq(md){
    bottom: 0;
    }
}
.p-news__info{
    font-family: $base-font-family;
    font-size: rem(14);
    line-height: calc(24/15);
    font-weight:400;
    color: #A59F9F;
    margin-right: rem(105);
}
.p-news__block{
    font-family: $base-font-family;
    font-size: rem(15);
    line-height: calc(24/15);
    font-weight:400;
    color: $black;
}
.p-news__btn{
    text-align: center;
}
// news一覧ページ
.p-news__list{
    background-color: $white;
}