@use "global" as *;
.p-works1__inner,
.p-works2__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    @include mq(md){
    padding-left: rem(25);
    padding-right: rem(25);
}
}
.p-works1__title,
.p-works2__title{
    font-family:$second-font-family;
    font-size: rem(30);
    font-weight: bold;
    line-height: calc(60/50);
    letter-spacing: 0.1em;
    color: $blue;
    text-align: center;
    @include mq(md){
    font-size: rem(50);
}
}
.p-works1-top,.p-works2-top{
    font-family: $base-font-family;
    font-size: rem(14);
    font-weight: 400;
    line-height: calc(24/16);
    letter-spacing: 0.1em;
    @include mq(md){
    font-size: rem(16);
    }
}
.p-works1-top h3,
.p-works2-top h3{
    font-size: rem(18);
    line-height: calc(30/20);
    position: relative;
    border-bottom: 1px solid #333;
    display: inline-block;
    @include mq(md){
    font-size: rem(20);
}
}
.p-works1-images
,.p-works2-images{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 30px;
    @include mq(md){
    grid-template-columns: repeat(3,1fr);
    gap: rem(30);
}
}
.p-works1-image p,
.p-works2-image p{
   font-family: $base-font-family;
   font-size: rem(14);
   font-weight: 400;
   line-height: calc(24/16);
   letter-spacing: 0.05em;
   margin-top: rem(10);
   @include mq(md){
    font-size: rem(16);
}
}