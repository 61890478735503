@use "global" as *;
.l-works1,
.l-works2{
    margin-top: rem(90);
    @include mq(md){
    margin-top: rem(120);
    }
}
.l-works1-top,
.l-works2-top{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(60);
}
}
.l-works1__text2,
.l-works1__text3,
.l-works2__text2,
.l-works2__text3{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(30);
}
}
.l-works1__text1 p,
.l-works1__text2 p,
.l-works1__text3 p,
.l-works2__text1 p,
.l-works2__text2 p,
.l-works2__text3 p{
    margin-top: rem(10);
    @include mq(md){
    margin-top: rem(15);
}
}
.l-works1-images,
.l-works2-images{
    margin-top: rem(30);
    @include mq(md){
    margin-top: rem(60);
}
}
