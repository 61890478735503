@use "global" as *;

.p-cadDesign__inner{
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(20);
    padding-right: rem(20);
    padding-bottom: rem(40);
    border-bottom: 1px solid #000;
    @include mq(md) {
    padding-left: rem(25);
    padding-right: rem(25);
    padding-bottom: rem(80);
}
}
.p-cadDesign__title{
    font-family: $base-font-family;
    font-size: rem(20);
    font-weight: bold;
    line-height: calc(30/20);
    letter-spacing: 0.1em;
    color: #000;
    @include mq(md) {
    font-size: rem(30);
    line-height: calc(40/30);
    }
}
.p-cadDesign__body-wrap{
    display: block;
    @include mq(md) {
    display: flex;
    justify-content: space-between;
}
}
.p-cadDesign__body{
    width: 100%;
    @include mq(md) {
    width: 40.7%;
}
}
.p-cadDesign__body p{
    font-family: $base-font-family;
    font-size: rem(16);
    font-weight:400;
    line-height: calc(30/20);
    letter-spacing: 0.05em;
    @include mq(md) {
    font-size: rem(20);
}
}
.p-cadDesign__img{
    width: 100%;
    margin-top: rem(30);
    @include mq(md) {
    width: 43.8%;
    margin-top: 0;
}
}