@use "global" as *;
.l-secondPlant{
    margin-top: rem(90);
    @include mq(md){
    margin-top: rem(120);
    }
}
.l-secondPlant-top__body{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(40);
    }
}
.l-secondPlant-thermosettingProduction,
.l-secondPlant-moldingMachine,
.l-secondPlant-moldCleaning,
.l-secondPlant-processingMachine,
.l-secondPlant-cad,
.l-secondPlant-measuringInstrument{
    margin-top: rem(40);
    @include mq(md){
    margin-top: rem(80);
    }
}
.l-secondPlant-thermosettingProduction__body1,
.l-secondPlant-thermosettingProduction__body2,
.l-secondPlant-moldingMachine__list,
.l-secondPlant-moldCleaning__body,
.l-secondPlant-processingMachine__list,
.l-secondPlant-measuringInstrument-list{
    margin-top: rem(20);
    @include mq(md){
    margin-top: rem(40);
    }
}
.l-secondPlant-moldCleaning__images,
.l-secondPlant-processingMachine__images,
.l-secondPlant-measuringInstrument__items{
    margin-top: rem(30);
    @include mq(md){
        margin-top: rem(60);
        }
}