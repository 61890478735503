@use "global" as *;
.l-mesuringMachine{
    margin-top: rem(40);
    @include mq(md) {
        margin-top: rem(80);
}
}
.l-mesuringMachine-top{
    margin-top: rem(20);
    @include mq(md) {
        margin-top: rem(40);
}
}
.l-mesuringMachine__body1{
    margin-top: rem(30);
    @include mq(md) {
    margin-top: rem(60);
}
}
.l-mesuringMachine__body2{
    margin-top: rem(40);
    @include mq(md) {
    margin-top: rem(60);
    }
}
.l-mesuringMachine__body-text{
    margin-top: rem(20);
}